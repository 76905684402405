<script lang="ts">
  import { onMount } from 'svelte';
  import { page } from '$app/stores';
  // Utils
  import { stringToSlug } from '@24hbs/utils';
  import { isMobileBrowser } from '$lib/utils/browser-utils';
  import { ContactUtils } from '$lib/utils/contact-utils';
  import { GtmEventCategory, GtmStore, MenuGtmEventId } from '$lib/utils/gtm-store';
  import { openModal } from "$lib/utils/modals";
  // Components
  import { Icon } from '$lib/components/atoms/Icon'
  import { PromoLabel } from '$lib/components/atoms/Countdown'
  import { NavbarLogo } from '$lib/components/atoms/NavbarLogo';
  import { Navbar, NavbarMobile, SideNavbar, SideNavbarMobile } from '$lib/components/organisms/Layout'
  import { 
    MegaMenu, MegaMenuLinks, MegaMenuLinksMobile, MegaMenuMobile, MegaMenuPanel, MegaMenuPanelGroup,
    NavbarAccount, NavbarAccountMobile, NavbarSearch, NavbarToggle, NavigationBackdrop
  } from '$lib/components/organisms/Navigation'
  import { CallBookingModal } from '$lib/components/organisms/Modal/';
  import { SearchForm } from '$lib/components/organisms/Form';
	import { 
    SearchAutocompletion, 
    SearchQuickLinksFrequent, 
    SearchQuickLinksHistory, 
    SearchQuickLinksPopular 
  } from '$lib/components/molecules/Search';

  export let segment: string | undefined;
  export let fixed = true;

  let openMegaMenu: 'programmi' | 'percorsi' | 'tematiche' | 'per le imprese' | 'cerca' | null = null;
  let sideNavbarVisible = false;
  let mobileNavbarVisible = false;
  let hasNavOpen = false;

  onMount(() => {
    closeAllPanels();
    checkNavigationOpened();
  });

  const checkNavigationOpened = () => {
    if (typeof window !== 'undefined') {
      if (hasNavOpen) {
        document?.body.classList.add('is-navigation-open');
        //console.log('Adding navigation open');
        // hasNavOpen = true;
      } else {
        document?.body.classList.remove('is-navigation-open');
        //console.log('Adding navigation open');
        // hasNavOpen = false;
      }
    }
  };

  const onSideNavbarToggle = () => {
    openMegaMenu = null;
    mobileNavbarVisible = false;
    sideNavbarVisible = !sideNavbarVisible;
  };

  const onSideNavbarClose = () => {
    openMegaMenu = null;
    mobileNavbarVisible = false;
    sideNavbarVisible = false;
    closeAllPanels();
  };

  const onMobileNavigationToggle = () => {
    openMegaMenu = null;
    sideNavbarVisible = false;
    mobileNavbarVisible = !mobileNavbarVisible;
  };

  const closeAllPanels = () => {
    openMegaMenu = null;
    sideNavbarVisible = false;
    mobileNavbarVisible = false;
    hasNavOpen = false;
  };

  const triggerClickOnMenuItem = (eventId: MenuGtmEventId, firstCategory, secondCategory, destinationUrl) => {
    closeAllPanels();
    GtmStore.pushEvent(
      {
        event: 'BBevent',
        event_id: eventId.toString(),
        category: GtmEventCategory.MENU_BAR,
        action: firstCategory,
        label: secondCategory,
        destination_url: destinationUrl,
      },
      false
    );
  };

  const onMegaMenuClose = () => {
    openMegaMenu = null;
  };

  const onToggleMegaMenu = (mega: typeof openMegaMenu) => {
    openMegaMenu = mega === openMegaMenu ? null : mega;
    return false;
  };

  const handleContactsClick = () => {
    if (isMobileBrowser() && ContactUtils.canDirectCall()) {
      window.open('tel:02-37929700');
    } else {
      openModal(CallBookingModal)
    }
  };


  $: if ($page.url.pathname) {
    closeAllPanels();
  }

  $: {
    hasNavOpen =
      (openMegaMenu !== null && typeof openMegaMenu !== undefined) || sideNavbarVisible || mobileNavbarVisible;
    checkNavigationOpened();
  }

  hasNavOpen = (openMegaMenu !== null && typeof openMegaMenu !== undefined) || sideNavbarVisible || mobileNavbarVisible;
  checkNavigationOpened();
</script>


<!-- Set fluid to 'true' to extend it full width -->
<Navbar {fixed} fluid={false}>
  <NavbarLogo on:click={() => (openMegaMenu = null)} />

  <SideNavbar visible={sideNavbarVisible} on:togglevisibility={onSideNavbarClose} />

  <svelte:fragment slot="topnavbar">
    <div class="w-full hidden lg:flex justify-end gap-x-6">
      <a
        on:click={() =>
          triggerClickOnMenuItem(
            MenuGtmEventId.TOP_MENU_CHI_SIAMO,
            'Top Menu',
            'Chi siamo',
            '/about'
          )}
        href="/about"
      >
        Chi siamo
      </a>
      <a
        class="flex items-center"
        href="/contact-us"
        on:click|preventDefault={() => handleContactsClick()}
      >
        <Icon icon="phone" style="margin-right: 2px;" />
        <span style="overflow: hidden; white-space:nowrap"> 02-37929700</span>
      </a>
      <a
        on:click={() =>
          triggerClickOnMenuItem(
            MenuGtmEventId.TOP_MENU_CONTATTACI,
            'Top Menu',
            'Contattaci',
            '/contact-us'
          )}
        href="/contact-us"
      >
        Contattaci
      </a>
      <span>Sedi: 
      <a
        on:click={() =>
          triggerClickOnMenuItem(
            MenuGtmEventId.TOP_MENU_SEDI_MILANO,
            'Top Menu',
            'Sedi - Milano',
            '/sedi#milano'
          )}
        href="/sedi#milano"
      >
        Milano
      </a>
      |
      <a
        on:click={() =>
          triggerClickOnMenuItem(
            MenuGtmEventId.TOP_MENU_SEDI_ROMA,
            'Top Menu',
            'Sedi - Roma',
            '/sedi#roma'
          )}
        href="/sedi#roma"
      >
        Roma
      </a>
    </div>
  </svelte:fragment>

  <svelte:fragment slot="main">
    <MegaMenuLinks>
      <a
        href="#/"
        on:click|preventDefault={() => onToggleMegaMenu('programmi')}
        aria-expanded={openMegaMenu === 'programmi' ? true : undefined}
        aria-haspopup="true"
      >
        Programmi
      </a>
      <a
        href="#/"
        on:click|preventDefault={() => onToggleMegaMenu('percorsi')}
        aria-expanded={openMegaMenu === 'percorsi' ? true : undefined}
        aria-haspopup="true"
      >
        Percorsi
      </a>
      <a
        href="#/"
        on:click|preventDefault={() => onToggleMegaMenu('tematiche')}
        aria-expanded={openMegaMenu === 'tematiche' ? true : undefined}
        aria-haspopup="true"
      >
        Tematiche
      </a>
      <a
        href="#/"
        on:click|preventDefault={() => onToggleMegaMenu('per le imprese')}
        aria-expanded={openMegaMenu === 'per le imprese' ? true : undefined}
        aria-haspopup="true"
      >
        Per le imprese
      </a>
      <a
        on:click={() =>
          triggerClickOnMenuItem(
            MenuGtmEventId.INIZIATIVE,
            'Iniziative',
            '',
            '/eventi'
          )}
        href="/eventi"
        target="_self"
      >
        Iniziative
      </a>
    </MegaMenuLinks>
    {#if !mobileNavbarVisible}
      <NavbarSearch on:click={() => onToggleMegaMenu('cerca')} current={segment === 'search'} />
    {/if}
    <NavbarAccount />
    <NavbarToggle displayOn="mobile" isToggled={mobileNavbarVisible} on:click={onMobileNavigationToggle} />
    <NavbarToggle displayOn="desktop" on:click={onSideNavbarToggle} />
  </svelte:fragment>

  <svelte:fragment slot="drawers">
    <NavbarMobile visible={mobileNavbarVisible} on:close={onMobileNavigationToggle}>
      <!-- ProfilerCardMobile / -->
      <MegaMenuLinksMobile>
        <a
          href="#/"
          on:click|preventDefault={() => onToggleMegaMenu('programmi')}
          aria-expanded={openMegaMenu === 'programmi' ? true : undefined}
          aria-haspopup="true"
        >
          Programmi
        </a>
        <a
          href="#/"
          on:click|preventDefault={() => onToggleMegaMenu('percorsi')}
          aria-expanded={openMegaMenu === 'percorsi' ? true : undefined}
          aria-haspopup="true"
        >
          Percorsi
        </a>
        <a
          href="#/"
          on:click|preventDefault={() => onToggleMegaMenu('tematiche')}
          aria-expanded={openMegaMenu === 'tematiche' ? true : undefined}
          aria-haspopup="true"
        >
          Tematiche
        </a>
        <a
          href="#/"
          on:click|preventDefault={() => onToggleMegaMenu('per le imprese')}
          aria-expanded={openMegaMenu === 'per le imprese' ? true : undefined}
          aria-haspopup="true"
        >
          Per le imprese
        </a>
        <a
          on:click={() =>
            triggerClickOnMenuItem(
              MenuGtmEventId.INIZIATIVE,
              'Iniziative',
              '',
              '/eventi'
            )}
          href="/eventi"
          target="_self"
        >
          Iniziative
        </a>
        <a
          href="/contact-us"
          style="display: flex; align-items: center"
          on:click|preventDefault={() => handleContactsClick()}
        >
          <Icon icon="phone" style="margin-right: 5px;" />
          <span style="overflow: hidden; white-space:nowrap"> 02-37929700</span>
        </a>
      </MegaMenuLinksMobile>

      <SideNavbarMobile />
      <NavbarAccountMobile />

      <svelte:fragment slot="drawers">
        <MegaMenuMobile visible={openMegaMenu === 'programmi'} on:back={onMegaMenuClose}>
          <MegaMenuPanel title="" colsCount="four">
            <MegaMenuPanelGroup title="Programmi Full Time">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MASTER_FULL_TIME_CON_STAGE,
                    'Programmi',
                    'Programmi Full Time - Master Post Laurea con Stage garantito',
                    '/master-full-time'
                  )}
                href="/master-full-time"
                target="_self"
                data-sveltekit-reload
              >
                Master Post Laurea con Stage garantito
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_MBA_FULL_TIME,
                    'Percorsi',
                    'Neo Laureati - MBA Full Time ',
                    '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
                  )}
                href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
                target="_self"
                data-sveltekit-reload
              >
                MBA Full Time 
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="Executive Part Time ">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_EXECUTIVE_MASTER,
                    'Programmi',
                    'Executive Part Time  - Executive Master',
                    '/executive-master'
                  )}
                href="/executive-master"
                target="_self"
                data-sveltekit-reload
              >
                Executive Master
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MBA_PART_TIME,
                    'Programmi',
                    'Executive Part Time  - MBA Part Time',
                    '/formazione/mba-part-time/mba-part-time'
                  )}
                href="/formazione/mba-part-time/mba-part-time"
                target="_self"
                data-sveltekit-reload
              >
                MBA Part Time
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_PROFESSIONAL_MASTER,
                    'Programmi',
                    'Executive Part Time  - Professional Master',
                    '/professional-master'
                  )}
                href="/professional-master"
                target="_self"
                data-sveltekit-reload
              >
                Professional Master
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_CORSI_EXECUTIVE,
                    'Programmi',
                    'Executive Part Time  - Corsi Executive',
                    '/corsi-executive'
                  )}
                href="/corsi-executive"
                target="_self"
                data-sveltekit-reload
              >
                Corsi Executive
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MASTER_PART_TIME,
                    'Programmi',
                    'Executive Part Time  - Master Part Time',
                    '/master-part-time'
                  )}
                href="/master-part-time"
                target="_self"
                data-sveltekit-reload
              >
                Vedi tutti i Master Part Time
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="MBA & Executive MBA">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_EXECUTIVE_MBA,
                    'Programmi',
                    'MBA & Executive MBA - Executive MBA',
                    '/mba'
                  )}
                href="/mba"
                target="_self"
                data-sveltekit-reload
              >
                Executive MBA
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MBA_FULL_TIME,
                    'Programmi',
                    'MBA & Executive MBA - MBA Full Time',
                    '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
                  )}
                href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
                target="_self"
                data-sveltekit-reload
              >
                MBA Full Time
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MBA_PART_TIME,
                    'Programmi',
                    'MBA & Executive MBA - MBA Part Time',
                    '/formazione/mba-part-time/mba-part-time'
                  )}
                href="/formazione/mba-part-time/mba-part-time"
                target="_self"
                data-sveltekit-reload
              >
                MBA Part Time
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MBA_EMBA,
                    'Programmi',
                    'MBA & Executive MBA - MBA EMBA',
                    '/mba-emba'
                  )}
                href="/mba-emba"
                target="_self"
                data-sveltekit-reload
              >
                Vedi tutti gli MBA
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="Altri Corsi">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_CORSI_ON_DEMAND,
                    'Programmi',
                    'Altri Corsi - Corsi Online',
                    '/corsi-online'
                  )}
                href="/corsi-online"
                target="_self"
                data-sveltekit-reload
              > 
                Corsi Online
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MASTER_EUROCONFERENCE,
                    'Programmi',
                    'Altri Corsi - Corsi Euroconference',
                    '/master-euroconference'
                  )}
                href="/master-euroconference"
                target="_self"
                data-sveltekit-reload
              >   
                Corsi Euroconference         
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MASTERCLASS,
                    'Programmi',
                    'Altri Corsi - Masterclass',
                    '/masterclass'
                  )}
                href="/masterclass"
                target="_self"
                data-sveltekit-reload
              >
                Masterclass
              </a>
            </MegaMenuPanelGroup>
          </MegaMenuPanel>

          <NavbarAccountMobile slot="footer" />
        </MegaMenuMobile>
        <MegaMenuMobile visible={openMegaMenu === 'percorsi'} on:back={onMegaMenuClose}>
          <MegaMenuPanel title="Scegli per tipologia" colsCount="three">
            <MegaMenuPanelGroup title="Professionisti e Executive ">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_EXECUTIVE_MASTER,
                    'Percorsi',
                    'Professionisti e Executive  - Executive Master ',
                    '/executive-master'
                  )}
                href="/executive-master"
                target="_self"
                data-sveltekit-reload
              >
                Executive Master 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_EXECUTIVE_MBA,
                    'Percorsi',
                    'Professionisti e Executive  - Executive MBA ',
                    '/mba'
                  )}
                href="/mba"
                target="_self"
                data-sveltekit-reload
              >
                Executive MBA 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_MBA_PART_TIME,
                    'Percorsi',
                    'Professionisti e Executive  - MBA Part Time ',
                    '/formazione/mba-part-time/mba-part-time'
                  )}
                href="/formazione/mba-part-time/mba-part-time"
                target="_self"
                data-sveltekit-reload
              >
                MBA Part Time 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_CORSI_EXECUTIVE,
                    'Percorsi',
                    'Professionisti e Executive  - Corsi Executive',
                    '/corsi-executive'
                  )}
                href="/corsi-executive"
                target="_self"
                data-sveltekit-reload
              >
                Corsi Executive
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="Neo Laureati">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_MASTER_POST_LAUREA_CON_STAGE,
                    'Percorsi',
                    'Neo Laureati - Master Post Laurea con Stage garantito ',
                    '/master-full-time'
                  )}
                href="/master-full-time"
                target="_self"
                data-sveltekit-reload
              >
                Master Post Laurea con Stage  garantito
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_MBA_FULL_TIME,
                    'Percorsi',
                    'Neo Laureati - MBA Full Time ',
                    '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
                  )}
                href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
                target="_self"
                data-sveltekit-reload
              >
                MBA Full Time 
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="Open Programme">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_PROFESSIONAL_MASTER,
                    'Percorsi',
                    'Open Programme - Professional Master ',
                    '/professional-master'
                  )}
                href="/professional-master"
                target="_self"
                data-sveltekit-reload
              >
                Professional Master 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_CORSI_ONLINE,
                    'Percorsi',
                    'Open Programme - Corsi Online ',
                    '/corsi-online'
                  )}
                href="/corsi-online"
                target="_self"
                data-sveltekit-reload
              >
                Corsi Online 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_CORSI_EUROCONFERENCE,
                    'Percorsi',
                    'Open Programme - Corsi Euroconference ',
                    '/master-euroconference'
                  )}
                href="/master-euroconference"
                target="_self"
                data-sveltekit-reload
              >
                Corsi Euroconference 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_MASTERCLASS,
                    'Percorsi',
                    'Open Programme - Masterclass',
                    '/masterclass'
                  )}
                href="/masterclass"
                target="_self"
                data-sveltekit-reload
              >
                Masterclass
              </a>
            </MegaMenuPanelGroup>
          </MegaMenuPanel>

          <NavbarAccountMobile slot="footer" />
        </MegaMenuMobile>

        <MegaMenuMobile visible={openMegaMenu === 'tematiche'} on:back={onMegaMenuClose}>
          <MegaMenuPanel title="Scegli per interesse" colsCount="one">
            <MegaMenuPanelGroup title="" linkStyle="title">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.MARKETING_COMUNICAZIONE_DIGITAL_E_SOCIAL_MEDIA,
                    'Tematiche',
                    'Marketing, Comunicazione, Digital e Social media',
                    `/${stringToSlug('Marketing, Comunicazione, Digital e Social media')}`
                  )}
                href="/{stringToSlug('Marketing, Comunicazione, Digital e Social media')}"
                target="_self"
                data-sveltekit-reload
              >
                Marketing, Comunicazione, Digital e Social media
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.GESTIONE_DI_IMPRESA_EXPORT_E_INTERNAZIONALIZZAZIONE,
                    'Tematiche',
                    'Gestione di Impresa, Export e Internazionalizzazione',
                    `/${stringToSlug('Gestione di Impresa, Export e Internazionalizzazione')}`
                  )}
                href="/{stringToSlug('Gestione di Impresa, Export e Internazionalizzazione')}"
                target="_self"
                data-sveltekit-reload
              >
                Gestione di Impresa, Export e Internazionalizzazione</a
              >
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.HR_LAVORO_E_SVILUPPO_MANAGERIALE,
                    'Tematiche',
                    'HR, Lavoro e Sviluppo Manageriale',
                    `/${stringToSlug('HR, Lavoro e Sviluppo Manageriale')}`
                  )}
                href="/{stringToSlug('HR, Lavoro e Sviluppo Manageriale')}"
                target="_self"
                data-sveltekit-reload
              >
                HR, Lavoro e Sviluppo Manageriale</a
              >
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.AMMINISTRAZIONE_FINANZA_E_CONTROLLO,
                    'Tematiche',
                    'Amministrazione, finanza e Controllo',
                    `/${stringToSlug('Amministrazione, finanza e Controllo')}`
                  )}
                href="/{stringToSlug('Amministrazione, finanza e Controllo')}"
                target="_self"
                data-sveltekit-reload
              >
                Amministrazione, finanza e Controllo
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.LUSSO_E_MODA,
                    'Tematiche',
                    'Lusso e Moda',
                    `/${stringToSlug('Lusso e Moda')}`
                  )}
                href="/{stringToSlug('Lusso e Moda')}"
                target="_self"
                data-sveltekit-reload
              >
                Lusso e Moda
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.VENDITE_E_RETAIL,
                    'Tematiche',
                    'Vendite e Retail',
                    `/${stringToSlug('Vendite e Retail')}`
                  )}
                href="/{stringToSlug('Vendite e Retail')}"
                target="_self"
                data-sveltekit-reload
              >
                Vendite e Retail
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.MBA_EMBA,
                    'Tematiche',
                    'MBA',
                    `/mba-emba`
                  )}
                href="/mba-emba"
                target="_self"
                data-sveltekit-reload
              >
                MBA
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.AMBIENTE_ENERGIA_E_PA,
                    'Tematiche',
                    'Ambiente Energia e P.A.',
                    `/${stringToSlug('Ambiente Energia e P.A.')}`
                  )}
                href="/{stringToSlug('Ambiente Energia e P.A.')}"
                target="_self"
                data-sveltekit-reload
              >
                Ambiente Energia e P.A.
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.SANITA_PHARMA_E_BIOMED,
                    'Tematiche',
                    'Sanità, Pharma e Biomed',
                    `/${stringToSlug('Sanità, Pharma e Biomed')}`
                  )}
                href="/{stringToSlug('Sanità, Pharma e Biomed')}"
                target="_self"
                data-sveltekit-reload
              >
                Sanità, Pharma e Biomed
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.ARTE_CINEMA_E_DESIGN,
                    'Tematiche',
                    'Arte, Cinema e Design',
                    `/${stringToSlug('Arte, Cinema e Design')}`
                  )}
                href="/{stringToSlug('Arte, Cinema e Design')}"
                target="_self"
                data-sveltekit-reload
              >
                Arte, Cinema e Design
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.DIRITTO_E_FISCO,
                    'Tematiche',
                    'Diritto e Fisco',
                    `/${stringToSlug('Diritto e Fisco')}`
                  )}
                href="/{stringToSlug('Diritto e Fisco')}"
                target="_self"
                data-sveltekit-reload
              >
                Diritto e Fisco
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.FOOD_BEVERAGE_E_TURISMO,
                    'Tematiche',
                    'Food, Beverage e Turismo',
                    `/${stringToSlug('Food, Beverage e Turismo')}`
                  )}
                href="/{stringToSlug('Food, Beverage e Turismo')}"
                target="_self"
                data-sveltekit-reload
              >
                Food, Beverage e Turismo
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(MenuGtmEventId.SPORT, 'Tematiche', 'Sport', `/${stringToSlug('Sport')}`)}
                href="/{stringToSlug('Sport')}"
                target="_self"
                data-sveltekit-reload
              >
                Sport
              </a>
            </MegaMenuPanelGroup>
          </MegaMenuPanel>

          <NavbarAccountMobile slot="footer" />
        </MegaMenuMobile>

        <MegaMenuMobile visible={openMegaMenu === 'per le imprese'} on:back={onMegaMenuClose}>
          <MegaMenuPanel title="Entra nel mondo B2B" colsCount="three">
            <MegaMenuPanelGroup title="" linkStyle="heading">
              <a
                on:click={() => triggerClickOnMenuItem(MenuGtmEventId.PER_LE_IMPRESE, 'B2B', 'Servizi per le imprese', '/b2b')}
                href="/b2b"
                target="_self"
                data-sveltekit-reload
              >
                Servizi per le imprese
              </a>
              <ul>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.JOB_FINDING, 'B2B', 'Job finding', '/b2b/job-finding')}
                  href="/b2b/job-finding"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Job Finding</li>
                </a>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.SKILLING, 'B2B', 'Skilling', '/b2b/skilling')}
                  href="/b2b/skilling"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Skilling</li>
                </a>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.BRANDING, 'B2B', 'Branding', '/b2b/branding')}
                  href="/b2b/branding"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Branding</li>
                </a>

                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.BRANDING, 'B2B', 'Finance', '/b2b/finance')}
                  href="/b2b/finance"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Finance</li>
                </a>
              </ul>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="" linkStyle="heading">
              <p>
                Offerta
              </p>
              <ul>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende')}
                  href="/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Formazione Interaziendale a catalogo</li>
                </a>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-formazione-su-misura')}
                  href="/b2b/offerta/b2b-formazione-su-misura"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Formazione su Misura</li>
                </a>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-hr-for-breakfast')}
                  href="/b2b/offerta/b2b-hr-for-breakfast"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">HR for Breakfast</li>
                </a>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-le-masterclass')}
                  href="/b2b/offerta/b2b-le-masterclass"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Le Masterclass</li>
                </a>
              </ul>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="" linkStyle="heading">
              <p>
                Opportunità
              </p>
              <ul>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OPPORTUNITA, 'B2B', 'opportunityB2b', '/b2b/opportunita/b2b-fondo-nuove-competenze')}
                  href="/b2b/opportunita/b2b-fondo-nuove-competenze"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Fondo Nuove Competenze</li>
                </a>
              </ul>
            </MegaMenuPanelGroup>
          </MegaMenuPanel>

          <NavbarAccountMobile slot="footer" />
        </MegaMenuMobile>
      </svelte:fragment>
    </NavbarMobile>

    <MegaMenu open={openMegaMenu === 'programmi'} on:close={onMegaMenuClose}>
      <MegaMenuPanel title="" colsCount="four">
        <MegaMenuPanelGroup title="Programmi Full Time">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MASTER_FULL_TIME_CON_STAGE,
                'Programmi',
                'Programmi Full Time - Master Post Laurea con Stage garantito',
                '/master-full-time'
              )}
            href="/master-full-time"
            target="_self"
            data-sveltekit-reload
          >
            Master Post Laurea con Stage garantito
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_MBA_FULL_TIME,
                'Percorsi',
                'Neo Laureati - MBA Full Time ',
                '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
              )}
            href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
            target="_self"
            data-sveltekit-reload
          >
            MBA Full Time 
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="Executive Part Time ">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_EXECUTIVE_MASTER,
                'Programmi',
                'Executive Part Time  - Executive Master',
                '/executive-master'
              )}
            href="/executive-master"
            target="_self"
            data-sveltekit-reload
          >
            Executive Master
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MBA_PART_TIME,
                'Programmi',
                'Executive Part Time  - MBA Part Time',
                '/formazione/mba-part-time/mba-part-time'
              )}
            href="/formazione/mba-part-time/mba-part-time"
            target="_self"
            data-sveltekit-reload
          >
            MBA Part Time
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_PROFESSIONAL_MASTER,
                'Programmi',
                'Executive Part Time  - Professional Master',
                '/professional-master'
              )}
            href="/professional-master"
            target="_self"
            data-sveltekit-reload
          >
            Professional Master
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_CORSI_EXECUTIVE,
                'Programmi',
                'Executive Part Time  - Corsi Executive',
                '/corsi-executive'
              )}
            href="/corsi-executive"
            target="_self"
            data-sveltekit-reload
          >
            Corsi Executive
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MASTER_PART_TIME,
                'Programmi',
                'Executive Part Time  - Master Part Time',
                '/master-part-time'
              )}
            href="/master-part-time"
            target="_self"
            data-sveltekit-reload
          >
            Vedi tutti i Master Part Time
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="MBA & Executive MBA">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_EXECUTIVE_MBA,
                'Programmi',
                'MBA & Executive MBA - Executive MBA',
                '/mba'
              )}
            href="/mba"
            target="_self"
            data-sveltekit-reload
          >
            Executive MBA
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MBA_FULL_TIME,
                'Programmi',
                'MBA & Executive MBA - MBA Full Time',
                '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
              )}
            href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
            target="_self"
            data-sveltekit-reload
          >
            MBA Full Time
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MBA_PART_TIME,
                'Programmi',
                'MBA & Executive MBA - MBA Part Time',
                '/formazione/mba-part-time/mba-part-time'
              )}
            href="/formazione/mba-part-time/mba-part-time"
            target="_self"
            data-sveltekit-reload
          >
            MBA Part Time
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MBA_EMBA,
                'Programmi',
                'MBA & Executive MBA - MBA EMBA',
                '/mba-emba'
              )}
            href="/mba-emba"
            target="_self"
            data-sveltekit-reload
          >
            Vedi tutti gli MBA
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="Altri Corsi">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_CORSI_ON_DEMAND,
                'Programmi',
                'Altri Corsi - Corsi Online',
                '/corsi-online'
              )}
            href="/corsi-online"
            target="_self"
            data-sveltekit-reload
          > 
            Corsi Online
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MASTER_EUROCONFERENCE,
                'Programmi',
                'Altri Corsi - Corsi Euroconference',
                '/master-euroconference'
              )}
            href="/master-euroconference"
            target="_self"
            data-sveltekit-reload
          >   
            Corsi Euroconference         
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MASTERCLASS,
                'Programmi',
                'Altri Corsi - Masterclass',
                '/masterclass'
              )}
            href="/masterclass"
            target="_self"
            data-sveltekit-reload
          >
            Masterclass
          </a>
        </MegaMenuPanelGroup>
      </MegaMenuPanel>
    </MegaMenu>

    <MegaMenu open={openMegaMenu === 'percorsi'} on:close={onMegaMenuClose}>
      <MegaMenuPanel title="Scegli per tipologia" colsCount="three">
        <MegaMenuPanelGroup title="Professionisti e Executive ">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_EXECUTIVE_MASTER,
                'Percorsi',
                'Professionisti e Executive  - Executive Master ',
                '/executive-master'
              )}
            href="/executive-master"
            target="_self"
            data-sveltekit-reload
          >
            Executive Master 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_EXECUTIVE_MBA,
                'Percorsi',
                'Professionisti e Executive  - Executive MBA ',
                '/mba'
              )}
            href="/mba"
            target="_self"
            data-sveltekit-reload
          >
            Executive MBA 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_MBA_PART_TIME,
                'Percorsi',
                'Professionisti e Executive  - MBA Part Time ',
                '/formazione/mba-part-time/mba-part-time'
              )}
            href="/formazione/mba-part-time/mba-part-time"
            target="_self"
          >
            MBA Part Time 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_CORSI_EXECUTIVE,
                'Percorsi',
                'Professionisti e Executive  - Corsi Executive',
                '/corsi-executive'
              )}
            href="/corsi-executive"
            target="_self"
            data-sveltekit-reload
          >
            Corsi Executive
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="Neo Laureati">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_MASTER_POST_LAUREA_CON_STAGE,
                'Percorsi',
                'Neo Laureati - Master Post Laurea con Stage garantito ',
                '/master-full-time'
              )}
            href="/master-full-time"
            target="_self"
            data-sveltekit-reload
          >
            Master Post Laurea con Stage  garantito
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_MBA_FULL_TIME,
                'Percorsi',
                'Neo Laureati - MBA Full Time ',
                '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
              )}
            href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
            target="_self"
            data-sveltekit-reload
          >
            MBA Full Time 
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="Open Programme">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_PROFESSIONAL_MASTER,
                'Percorsi',
                'Open Programme - Professional Master ',
                '/professional-master'
              )}
            href="/professional-master"
            target="_self"
            data-sveltekit-reload
          >
            Professional Master 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_CORSI_ONLINE,
                'Percorsi',
                'Open Programme - Corsi Online ',
                '/corsi-online'
              )}
            href="/corsi-online"
            target="_self"
            data-sveltekit-reload
          >
            Corsi Online 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_CORSI_EUROCONFERENCE,
                'Percorsi',
                'Open Programme - Corsi Euroconference ',
                '/master-euroconference'
              )}
            href="/master-euroconference"
            target="_self"
            data-sveltekit-reload
          >
            Corsi Euroconference 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_MASTERCLASS,
                'Percorsi',
                'Open Programme - Masterclass',
                '/masterclass'
              )}
            href="/masterclass"
            target="_self"
            data-sveltekit-reload
          >
            Masterclass
          </a>
        </MegaMenuPanelGroup>
      </MegaMenuPanel>
    </MegaMenu>

    <MegaMenu open={openMegaMenu === 'tematiche'} on:close={onMegaMenuClose}>
      <MegaMenuPanel title="Scegli per interesse" colsCount="two">
        <MegaMenuPanelGroup title="" linkStyle="title">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.MARKETING_COMUNICAZIONE_DIGITAL_E_SOCIAL_MEDIA,
                'Tematiche',
                'Marketing, Comunicazione, Digital e Social media',
                `/${stringToSlug('Marketing, Comunicazione, Digital e Social media')}`
              )}
            href="/{stringToSlug('Marketing, Comunicazione, Digital e Social media')}"
            target="_self"
            data-sveltekit-reload
          >
            Marketing, Comunicazione, Digital e Social media
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.GESTIONE_DI_IMPRESA_EXPORT_E_INTERNAZIONALIZZAZIONE,
                'Tematiche',
                'Gestione di Impresa, Export e Internazionalizzazione',
                `/${stringToSlug('Gestione di Impresa, Export e Internazionalizzazione')}`
              )}
            href="/{stringToSlug('Gestione di Impresa, Export e Internazionalizzazione')}"
            target="_self"
            data-sveltekit-reload
          >
            Gestione di Impresa, Export e Internazionalizzazione</a
          >
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.HR_LAVORO_E_SVILUPPO_MANAGERIALE,
                'Tematiche',
                'HR, Lavoro e Sviluppo Manageriale',
                `/${stringToSlug('HR, Lavoro e Sviluppo Manageriale')}`
              )}
            href="/{stringToSlug('HR, Lavoro e Sviluppo Manageriale')}"
            target="_self"
            data-sveltekit-reload
          >
            HR, Lavoro e Sviluppo Manageriale</a
          >
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.AMMINISTRAZIONE_FINANZA_E_CONTROLLO,
                'Tematiche',
                'Amministrazione, finanza e Controllo',
                `/${stringToSlug('Amministrazione, finanza e Controllo')}`
              )}
            href="/{stringToSlug('Amministrazione, finanza e Controllo')}"
            target="_self"
            data-sveltekit-reload
          >
            Amministrazione, finanza e Controllo
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.LUSSO_E_MODA,
                'Tematiche',
                'Lusso e Moda',
                `/${stringToSlug('Lusso e Moda')}`
              )}
            href="/{stringToSlug('Lusso e Moda')}"
            target="_self"
            data-sveltekit-reload
          >
            Lusso e Moda
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.VENDITE_E_RETAIL,
                'Tematiche',
                'Vendite e Retail',
                `/${stringToSlug('Vendite e Retail')}`
              )}
            href="/{stringToSlug('Vendite e Retail')}"
            target="_self"
            data-sveltekit-reload
          >
            Vendite e Retail
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.MBA_EMBA,
                'Tematiche',
                'MBA',
                `/mba-emba`
              )}
            href="/mba-emba"
            target="_self"
            data-sveltekit-reload
          >
            MBA
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="" linkStyle="title">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.AMBIENTE_ENERGIA_E_PA,
                'Tematiche',
                'Ambiente Energia e P.A.',
                `/${stringToSlug('Ambiente Energia e P.A.')}`
              )}
            href="/{stringToSlug('Ambiente Energia e P.A.')}"
            target="_self"
            data-sveltekit-reload
          >
            Ambiente Energia e P.A.
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.SANITA_PHARMA_E_BIOMED,
                'Tematiche',
                'Sanità, Pharma e Biomed',
                `/${stringToSlug('Sanità, Pharma e Biomed')}`
              )}
            href="/{stringToSlug('Sanità, Pharma e Biomed')}"
            target="_self"
            data-sveltekit-reload
          >
            Sanità, Pharma e Biomed
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.ARTE_CINEMA_E_DESIGN,
                'Tematiche',
                'Arte, Cinema e Design',
                `/${stringToSlug('Arte, Cinema e Design')}`
              )}
            href="/{stringToSlug('Arte, Cinema e Design')}"
            target="_self"
            data-sveltekit-reload
          >
            Arte, Cinema e Design
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.DIRITTO_E_FISCO,
                'Tematiche',
                'Diritto e Fisco',
                `/${stringToSlug('Diritto e Fisco')}`
              )}
            href="/{stringToSlug('Diritto e Fisco')}"
            target="_self"
            data-sveltekit-reload
          >
            Diritto e Fisco
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.FOOD_BEVERAGE_E_TURISMO,
                'Tematiche',
                'Food, Beverage e Turismo',
                `/${stringToSlug('Food, Beverage e Turismo')}`
              )}
            href="/{stringToSlug('Food, Beverage e Turismo')}"
            target="_self"
            data-sveltekit-reload
          >
            Food, Beverage e Turismo
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(MenuGtmEventId.SPORT, 'Tematiche', 'Sport', `/${stringToSlug('Sport')}`)}
            href="/{stringToSlug('Sport')}"
            target="_self"
            data-sveltekit-reload
          >
            Sport
          </a>
        </MegaMenuPanelGroup>
      </MegaMenuPanel>
    </MegaMenu>

    <MegaMenu open={openMegaMenu === 'per le imprese'} on:close={onMegaMenuClose}>
      <MegaMenuPanel title="Entra nel mondo B2B" colsCount="three">
        <MegaMenuPanelGroup title="" linkStyle="heading">
          <a
            on:click={() => triggerClickOnMenuItem(MenuGtmEventId.PER_LE_IMPRESE, 'B2B', 'Servizi per le imprese', '/b2b')}
            href="/b2b"
            target="_self"
            data-sveltekit-reload
          >
            Servizi per le imprese
          </a>
          <ul>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.JOB_FINDING, 'B2B', 'Job finding', '/b2b/job-finding')}
              href="/b2b/job-finding"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Job Finding</li>
            </a>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.SKILLING, 'B2B', 'Skilling', '/b2b/skilling')}
              href="/b2b/skilling"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Skilling</li>
            </a>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.BRANDING, 'B2B', 'Branding', '/b2b/branding')}
              href="/b2b/branding"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Branding</li>
            </a>

            <a
            on:click={() => triggerClickOnMenuItem(MenuGtmEventId.BRANDING, 'B2B', 'Finance', '/b2b/finance')}
            href="/b2b/finance"
            target="_self"
            data-sveltekit-reload
          >
            <li class="ml-4">Finance</li>
          </a>
          </ul>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="" linkStyle="heading">
          <p>
            Offerta
          </p>
          <ul>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende')}
              href="/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Formazione Interaziendale a catalogo</li>
            </a>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-formazione-su-misura')}
              href="/b2b/offerta/b2b-formazione-su-misura"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Formazione su Misura</li>
            </a>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-hr-for-breakfast')}
              href="/b2b/offerta/b2b-hr-for-breakfast"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">HR for Breakfast</li>
            </a>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-le-masterclass')}
              href="/b2b/offerta/b2b-le-masterclass"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Le Masterclass</li>
            </a>
          </ul>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="" linkStyle="heading">
          <p>
            Opportunità
          </p>
          <ul>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OPPORTUNITA, 'B2B', 'opportunityB2b', '/b2b/opportunita/b2b-fondo-nuove-competenze')}
              href="/b2b/opportunita/b2b-fondo-nuove-competenze"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Fondo Nuove Competenze</li>
            </a>
          </ul>
        </MegaMenuPanelGroup>
      </MegaMenuPanel>
    </MegaMenu>

    <MegaMenu open={openMegaMenu === 'cerca'} on:close={onMegaMenuClose} noVerticalPadding showOnMobile>
      <SearchForm slot="header" />
      
      <svelte:fragment slot="footer">
        <SearchAutocompletion />
        <SearchQuickLinksHistory />
        <SearchQuickLinksFrequent />
        <SearchQuickLinksPopular />
      </svelte:fragment>
    </MegaMenu>
    
    <NavigationBackdrop visible={!!openMegaMenu || mobileNavbarVisible} on:click={closeAllPanels} />
  </svelte:fragment>
</Navbar>

<PromoLabel />
