<script lang="ts">
  // Components
  import { Grid } from '$lib/components/organisms/Layout'
</script>

<div class="mega-links-mobile">
  <Grid noGrid>
    <div class="links-mobile-list">
      <slot />
    </div>
  </Grid>
</div>

<style type="text/scss" lang="scss" scoped>
  .links-mobile-list {
    @apply flex flex-col;
  }

  :global(.links-mobile-list > a) {
    @apply relative flex items-center;
    @apply text-md tracking-widest uppercase;
    @apply border-t border-transparent;
    @apply py-3 pr-16;

    &::after {
      content: "";
      mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.588 8.418l3.88 3.88-3.88 3.88a.996.996 0 101.41 1.41l4.59-4.59a.996.996 0 000-1.41l-4.59-4.59a.996.996 0 00-1.41 0c-.38.39-.39 1.03 0 1.42z'/%3E%3C/svg%3E");
      @apply w-6 h-6;
      @apply absolute right-0 top-auto bottom-auto;
      @apply bg-gray-900;
      @apply transition-transform duration-200;
    }
    &:active {
      @apply bg-gray-300 bg-opacity-50;
    }
    &:active::after {
      @apply transform translate-x-2;
    }
  }

  :global(.links-mobile-list > a + a) {
    @apply border-gray-900 border-opacity-20;
  }
</style>
