<script lang="ts">
	// Stores
	import { useOktaStore } from '$lib/utils/auth-store';
	import { GtmEventCategory, GtmStore, MenuGtmEventId } from '$lib/utils/gtm-store';

	const authStore = useOktaStore();

	let loggedIn = authStore.isLoggedIn;

	const triggerClickOnMenuItem = (
		eventId: MenuGtmEventId,
		firstCategory?: string,
		secondCategory?: string,
		destinationUrl?: string
	) => {
		GtmStore.pushEvent(
			{
				event: 'BBevent',
				event_id: eventId.toString(),
				category: GtmEventCategory.MENU_BAR,
				action: firstCategory,
				label: secondCategory,
				destination_url: destinationUrl
			},
			false
		);
	};
</script>

<ul class="main-menu-list">
	<li class="main-menu-list-item">
		<a
			class="menu-item-link"
			on:click={() =>
				triggerClickOnMenuItem(
					MenuGtmEventId.HAMBURGER_CHI_SIAMO,
					'Hamburger Menu',
					'Chi siamo',
					'/about'
				)}
			href="/about"
			data-sveltekit-reload
		>
			Chi siamo
		</a>
	</li>
	<li class="main-menu-list-item">
		<a
			class="menu-item-link"
			on:click={() =>
				triggerClickOnMenuItem(
					MenuGtmEventId.HAMBURGER_CONTATTI,
					'Hamburger Menu',
					'Contatti',
					'/contact-us'
				)}
			href="/contact-us"
			data-sveltekit-reload
		>
			Contatti
		</a>
	</li>
	<li class="main-menu-list-item">
		<a
			class="menu-item-link"
			on:click={() =>
				triggerClickOnMenuItem(MenuGtmEventId.HAMBURGER_SEDI, 'Hamburger Menu', 'Sedi', '/sedi')}
			href="/sedi"
			data-sveltekit-reload
		>
			Sedi
		</a>
	</li>
	<li class="main-menu-list-item">
		<a
			class="menu-item-link"
			on:click={() =>
				triggerClickOnMenuItem(
					MenuGtmEventId.HAMBURGER_FINANZIAMENTI,
					'Hamburger Menu',
					'Finanziamenti',
					'/finanziamenti'
				)}
			href="/finanziamenti"
			data-sveltekit-reload
		>
			Finanziamenti
		</a>
	</li>
	<li class="main-menu-list-item">
		<a
			class="menu-item-link"
			on:click={() =>
				triggerClickOnMenuItem(
					MenuGtmEventId.HAMBURGER_DOCENTI,
					'Hamburger Menu',
					'Docenti',
					'/docenti'
				)}
			href="/docenti"
			data-sveltekit-reload
		>
			Docenti
		</a>
	</li>
	<li class="main-menu-list-item">
		<a
			class="menu-item-link"
			on:click={() =>
				triggerClickOnMenuItem(
					MenuGtmEventId.HAMBURGER_PARTNERS,
					'Hamburger Menu',
					'Partners',
					'/partners'
				)}
			href="/partners"
			data-sveltekit-reload
		>
			Partners
		</a>
	</li>
	<li class="main-menu-list-item">
		<a
			class="menu-item-link"
			on:click={() =>
				triggerClickOnMenuItem(MenuGtmEventId.HAMBURGER_NEWS, 'Hamburger Menu', 'News', '/news')}
			href="/news"
			data-sveltekit-reload
		>
			News
		</a>
	</li>
	<li class="main-menu-list-item">
		<a
			class="menu-item-link"
			on:click={() =>
				triggerClickOnMenuItem(MenuGtmEventId.HAMBURGER_FAQ, 'Hamburger Menu', 'FAQ', '/faq')}
			href="/faq"
			data-sveltekit-reload
		>
			FAQ
		</a>
	</li>
	<!-- Temporary hide link to reviews
  <li class="main-menu-list-item">
    <a
      class="menu-item-link"
      on:click={() =>
        triggerClickOnMenuItem(
          MenuGtmEventId.HAMBURGER_REVIEW,
          'Hamburger Menu',
          'Review',
          '/reviews'
        )}
      href="/reviews"
			data-sveltekit-reload
    >
      Review
    </a>
  </li> 
  -->
	{#if $loggedIn}
		<li class="main-menu-list-item">
			<a
				class="menu-item-link"
				on:click={() =>
					triggerClickOnMenuItem(
						MenuGtmEventId.HAMBURGER_E_LEARNING,
						'Hamburger Menu',
						'E-Learning',
						'https://elearning.24orebs.com/'
					)}
				href="https://elearning.24orebs.com/"
				rel="external"
			>
				E-Learning
			</a>
		</li>
	{/if}
</ul>

<style type="text/scss" lang="scss" scoped>
	.main-menu-list .main-menu-list-item + .main-menu-list-item {
		@apply mt-4 lg:mt-5;
	}

	.menu-item-link {
		@apply relative;
		@apply text-base lg:text-4xl text-gray-900 font-serif font-medium;
		@apply py-2 lg:py-0;

		&:after {
			content: '';
			@apply lg:absolute lg:left-0 lg:bottom-1.5;
			@apply lg:h-0.5 lg:bg-gray-900 lg:w-full lg:bg-opacity-30;
			@apply lg:transform lg:scale-x-0;
			@apply lg:ease-in-out lg:duration-200 lg:origin-right;
			transition-property: transform;
		}

		&:hover:after {
			@apply lg:transform lg:scale-x-100;
			@apply lg:origin-left;
		}
	}
</style>
