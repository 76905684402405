<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { fly } from "svelte/transition"
  import { expoInOut, expoOut } from "svelte/easing"
  // Components
  import { Icon } from '$lib/components/atoms/Icon'

  export let visible = false

  let panel: HTMLDivElement | undefined

  const dispatch = createEventDispatcher()
  const onBack = () => {
    dispatch("back")
  }
</script>

{#if visible}
  <div
    class="mega-menu-mobile"
    in:fly="{{ x: panel.clientWidth, opacity: 50, duration: 600, easing: expoOut }}"
    out:fly="{{ x: panel.clientWidth, opacity: 25, duration: 600, delay: 50, easing: expoInOut }}"
    bind:this="{panel}"
  >
    <div class="mega-menu-mobile-back" on:click="{onBack}">
      <Icon icon="chevron-left" />
    </div>

    {#if $$slots["default"]}
      <div class="mega-menu-mobile-content">
        <slot />
      </div>
    {/if}

    {#if $$slots["footer"]}
      <div class="mega-menu-mobile-footer">
        <slot name="footer" />
      </div>
    {/if}
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>
  .mega-menu-mobile {
    @apply row-start-1 col-span-full;
    @apply flex flex-col;
    @apply bg-gray-50;
    @apply z-1;
    @apply overflow-y-scroll;
    -webkit-overflow-scrolling: touch;
    min-height: calc(100vh - var(--navbar-height));
    /* Mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }

  .mega-menu-mobile-back {
    @apply px-2 py-4;
    @apply bg-transparent;
  }

  .mega-menu-mobile-footer {
    @apply mt-auto;
  }
</style>
