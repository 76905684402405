<script lang="ts">
	import { closeModal, openModal } from '$lib/utils/modals';
	import { clickOutside } from '$lib/events/clickOutside';
	import { Icon } from '$lib/components/atoms/Icon';
	import { CallBookingForm } from '$lib/components/organisms/Form';
  import { FeedbackCallBookingModal } from '$lib/components/organisms/Modal/';

	export let isOpen: boolean = false;

	const handleFeedbackCallBooking = (feedbackCallBooking: CustomEvent) => {
		//close CallBookingModal
    closeModal();
    //handle FeedbackCallBookingModal
    openModal(FeedbackCallBookingModal, { isSuccess: feedbackCallBooking.detail.success });
	};
</script>

{#if isOpen}
  <div
    class="modal sigin-modal"
    role="dialog"
    aria-labelledby="Effettua il login"
  >
    <div
      class="modal-dialog"
      use:clickOutside
      on:click_outside={closeModal}
    >
      <div class="modal-header">
        <div>
          <h2 class="modal-title">Prenota una chiamata</h2>
        </div>
        <div>
          <button
            class="close"
            aria-label="Chiudi"
            on:click={closeModal}
          >
            <Icon icon="close" />
          </button>
        </div>
      </div>
      <div class="modal-body">
        <CallBookingForm on:feedbackCallBooking={handleFeedbackCallBooking}/>
      </div>
    </div>
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>
	.modal {
		@apply fixed inset-0;
    @apply flex flex-col;
		@apply md:items-center md:justify-center;
    @apply z-modal;
	}
	.modal-dialog {
		@apply bg-white;
    @apply h-full;
    @apply flex flex-col;
    @apply w-full sm:max-w-md;
    @apply md:h-auto md:max-h-[90vh];
		@apply relative;
		@apply rounded md:rounded-lg;
		@apply shadow-xl;
		@apply p-6;
		@apply transform transition-all;
		@apply sm:my-8 sm:align-middle;
	}
	.modal-header {
		@apply flex items-start justify-between;
    @apply bg-white;
		@apply mb-2;
	}
	.modal-title {
		@apply text-2xl md:text-5xl;
		@apply leading-tight font-medium;
		@apply text-gray-900;
		@apply mt-2;
	}
	.modal-body {
		@apply flex-grow;
    @apply overflow-y-auto;
	}
	.close {
    @apply ml-auto;
    @apply transition-opacity duration-200;
    @apply hover:opacity-75;
	}
</style>
