<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { fly } from "svelte/transition";
  import { expoOut, expoInOut } from "svelte/easing";

  export let visible = false;

  let navbar: HTMLDivElement | undefined;

  const dispatch = createEventDispatcher();
  const onClose = () => {
    dispatch("close");
  };
</script>

<div
  class="navbar-mobile"
  style="transform: translateX({visible ? '0px' : navbar?.clientWidth + 'px'});opacity: {navbar ? 1 : 0}"
  class:visible
  in:fly={{
    x: navbar.clientWidth,
    opacity: 50,
    duration: 600,
    easing: expoOut,
  }}
  out:fly={{
    x: navbar.clientWidth,
    opacity: 25,
    duration: 400,
    easing: expoInOut,
  }}
  bind:this={navbar}
>
  <div class="navbar-mobile-content">
    <slot />
  </div>

  {#if $$slots["drawers"]}
    <div class="navbar-mobile-drawers">
      <slot name="drawers" />
    </div>
  {/if}
</div>

<style type="text/scss" lang="scss" scoped>
  .navbar-mobile {
    @apply grid grid-cols-1;
    @apply fixed inset-y-0 right-0 w-full;
    @apply max-w-md;
    @apply bg-gray-50;
    @apply overflow-y-auto overflow-x-hidden z-navbar;
    @apply lg:hidden;
    grid-template-rows: 1fr;
    top: var(--navbar-height);

    transition:
      transform 600ms ease-in-out,
      z-index 300ms ease,
      opacity 100ms ease 700ms;
    z-index: -100;
  }

  .navbar-mobile.visible {
    @apply z-side-navbar;
  }

  .navbar-mobile-content {
    @apply row-start-1 col-span-full;
    @apply overflow-y-scroll;
    @apply flex flex-col;
    -webkit-overflow-scrolling: touch;
  }
  .navbar-mobile-drawers {
    @apply contents;
  }
</style>
