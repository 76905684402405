<script lang="ts">
  // Components
  import { SideNavbarMainNav, SocialNav } from '$lib/components/organisms/Navigation';
</script>

<nav class="side-navbar-mobile">
  <div class="side-navbar-mobile-content">
    <div class="main-menu">
      <SideNavbarMainNav />
    </div>
    <div class="social-menu">
      <SocialNav />
    </div>
  </div>
</nav>

<style type="text/scss" lang="scss" scoped>
  .side-navbar-mobile {
    @apply flex flex-grow;
    @apply bg-brand;
  }

  /* Panel content */
  .side-navbar-mobile-content {
    @apply page-margin w-full py-6;
    @apply grid gap-y-8 md:gap-y-20;
    grid-template-rows: 1fr auto;
  }
</style>
