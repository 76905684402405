<script lang="ts">
  // Components
  import { Icon } from '$lib/components/atoms/Icon'
</script>

<ul class="social-menu-list">
  <li class="social-menu-list-item">
    <a class="social-item-link" target="_blank" href="https://www.facebook.com/24OREBusinessSchool"><Icon icon="facebook-alt" size="full" /></a>
  </li>
  <li class="social-menu-list-item">
    <a class="social-item-link" target="_blank" href="https://www.instagram.com/24ore_business_school/"><Icon icon="instagram" size="full" /></a>
  </li>
  <li class="social-menu-list-item">
    <a class="social-item-link" target="_blank" href="https://www.linkedin.com/school/24-ore-business-school/"><Icon icon="linkedin" size="full" /></a>
  </li>
  <li class="social-menu-list-item">
    <a class="social-item-link" target="_blank" href="https://twitter.com/24OREBS"><Icon icon="twitter" size="full" /></a>
  </li>
  <li class="social-menu-list-item">
    <a class="social-item-link" target="_blank" href="https://www.youtube.com/user/24OREBusinessSchool/"><Icon icon="youtube" size="full" /></a>
  </li>
</ul>

<style type="text/scss" lang="scss" scoped>
  .social-menu-list {
    @apply flex;
  }

  .social-menu-list-item {
    @apply inline-flex;
  }
  .social-menu-list .social-menu-list-item + .social-menu-list-item {
    @apply ml-4;
  }

  .social-item-link {
    @apply inline-flex w-6 h-6 lg:w-8 lg:h-8;
  }
</style>
