import { PUBLIC_CALLMEBACK } from '$env/static/public';
import type { CallmebackData, CallmebackResults } from './types';

export class ContactUtils {
  private static CALLMEBACK_API = PUBLIC_CALLMEBACK;
  private static getData = async (_fetch: {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }): Promise<CallmebackResults> => {
    const res = await _fetch(ContactUtils.CALLMEBACK_API);
    if (res.status !== 200) {
      return Promise.reject(res.json());
    } else {
      return Promise.resolve(res.json());
    }
  };

  public static sendData = async (data: CallmebackData) => {
    const res = await fetch(ContactUtils.CALLMEBACK_API, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    });

    if (res.status !== 200) {
      return Promise.reject(res.json());
    } else {
      return Promise.resolve(res.json());
    }
  };

  public static getAvailableDays = async (_fetch: {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }) => {
    const data = await ContactUtils.getData(_fetch);
    return data;
  };

  public static toFormattedDate = (timestamp: number) => {
    const date = new Date(timestamp);
    let formatter = new Intl.DateTimeFormat('it-IT', {
      day: '2-digit',
      month: 'long',
    });
    return formatter.format(date);
  };

  public static getAvailableHoursInDay = (
    data: CallmebackResults,
    day: number
  ) => {
    let availableHours = [];

    const selectedDay = data.nextDays.find((d) => d.day === day);
    if (selectedDay) {
      for (let i = selectedDay.hourFrom; i <= selectedDay.hourTo; i += 15) {
        availableHours.push(i);
      }
    }

    availableHours = availableHours.filter((hour) => {
      return !data.unavailable[day].includes(hour);
    });

    return availableHours;
  };

  public static toHour = (num) => {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;
    return (
      hours.toLocaleString('it-It', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ':' +
      minutes.toLocaleString('it-It', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    );
  };

  public static canDirectCall() {
    const today = new Date();
    const hour = today.getHours();
    const minutes = today.getMinutes();

    if (
      (today.getDay() == 6 &&
        (hour < 9 ||
          (hour == 9 && minutes < 30) ||
          hour > 12 ||
          (hour == 12 && minutes > 0))) ||
      (today.getDay() !== 0 &&
        (hour < 9 ||
          (hour == 9 && minutes < 30) ||
          hour > 18 ||
          (hour == 18 && minutes > 0)))
    ) {
      return false;
    } else {
      return true;
    }
  }
}
