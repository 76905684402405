<script lang="ts">
  /**
   * The modal component is a reusable component that can be used to display CTA: Apply Orientation.
   */
  import { GtmStore } from "$lib/utils/gtm-store";
  import { useOktaStore } from '$lib/utils/auth-store';
  import { useSessionStore } from '$lib/utils/session-store';
  import { useCookiesStore } from '$lib/utils/cookie-store';
  import { useOrientationStore } from "$lib/utils/orientation-store";
	import { goto } from "$app/navigation";
	import type { PopupModalSize } from "../Modal/PopupModal.svelte";

  import { Button } from "$lib/components/atoms/Button";
  import { PopupModal } from "$lib/components/organisms/Modal";
  import { ApplyOrientationForm, CallPreferenceForm } from '$lib/components/organisms/Form';
  
  /**
   * The Steps enum is used to define the steps of the modal.
   * @enum {string}
   */
  enum Steps {
    WELCOME,
    FORM,
    THANKS,
  }

  type Step = {
    step: Steps;
    title: string;
    size: PopupModalSize;
    showButton?: boolean;
    buttonAlign?: 'left' | 'center' | 'right';
    buttonLabel?: string;
  };

  const steps: Step[] = [
    {
      step: Steps.WELCOME,
      title: '',
      size: 'sm',
      buttonAlign: 'center',
      buttonLabel: 'Richiedi ora'
    },
    {
      step: Steps.FORM,
      title: 'Richiedi orientamento',
      size: 'md',
      buttonAlign: 'center',
      buttonLabel: 'Invia',
    },
    {
      step: Steps.THANKS,
      title: 'Richiesta inviata',
      size: 'md',
      showButton: false,
    }
  ]

  let currentStep: Step;
  $: currentStep = steps[0];
  $: currentStepIndex = steps.findIndex((step) => step.step === currentStep.step);

  let loading: boolean;
  $: loading = false;

  let highlevel: boolean = false;

  let applyOrientationForm;
  let applyOrientationCallPreferenceForm;

  const authStore = useOktaStore();
  const sessionStore = useSessionStore();
  const cookiesStore = useCookiesStore();
  const orientationStore = useOrientationStore()

  const userInfo = authStore.userInfo;
  const isLoggedIn = authStore.isLoggedIn;
  const pendingUserInfo = sessionStore.pendingUserInfo;
  const companyStatus = sessionStore.companyStatus;

  let isLastStepSubmitted: boolean = orientationStore.getIsLastStepSubmitted()
  
  /**
   * The handleButtonClick function is used to handle the button click event.
   * It dispatches the buttonClick event.
   * @returns {void}
   */
  const handleButtonClick = (moreInfo = false) => {
    highlevel = moreInfo;
    if (!$isLoggedIn && !$pendingUserInfo) {
      goto(`/login?callback=orientamento`)
    } else {
      if (currentStep.step === Steps.FORM) {
        loading = true;
        applyOrientationForm();
        return;
      }
      if (currentStep.step === Steps.THANKS) {
        loading = true;
        applyOrientationCallPreferenceForm();
        return;
      }
      handleNextStep();
    }
  };

  /**
   * The handleNextStep function is used to handle the next step event.
   * @returns {void}
   */
  const handleNextStep = () => {
    loading = false;
    isLastStepSubmitted = orientationStore.getIsLastStepSubmitted();
    if (isLastStepSubmitted && highlevel) {
      goto('/orientamento/highlevel');
    } else {   
      const newStepIndex = currentStepIndex + 1;
      if (newStepIndex >= steps.length) return;
      currentStep = steps[newStepIndex];
    }
  };


  $: {
    /******************** GTM EVENT PUSH *************************/
    if (currentStep.step === Steps.FORM || currentStep.step === Steps.THANKS) {
      GtmStore.pushEvent({
        event: 'pageview',
        pageCategory: currentStep.step === Steps.FORM ? 'Orientamento_1' : currentStep.step === Steps.THANKS ? 'Orientamento_2_ora' : 'NOT SET',
        Code: 'NOT SET',
        Naming: 'NOT SET',
        page_path: typeof window !== 'undefined' ? window.location.pathname : 'NOT SET',
        bsid: $isLoggedIn && $userInfo.bsid ? $userInfo.bsid : $pendingUserInfo ? $pendingUserInfo.bsid : 'NOT SET',
        UserStatus: $isLoggedIn ? 'true' : 'false',
        CP: cookiesStore.getCookie('cookie_notice_accepted'),
        Price: 'NOT SET',
        B2B: $companyStatus ? 'true' : 'false'
      });
    }
    /*************************************************************/
  }
</script>

<PopupModal
  bgColourClass="bg-gray-900 md:bg-white"
  titleColourClass="text-white md:text-gray-900"
  size={currentStep.size}
  buttonAlign={currentStep.buttonAlign}
  showButton={isLastStepSubmitted ? false : currentStep.showButton}
  buttonLabel={currentStep.buttonLabel}
  isWithRibbon
  ribbonText="Orientamento"
  {loading}
  noslide={currentStep.step == Steps.WELCOME}
  on:buttonClick={() => handleButtonClick()}
>
  <span slot="title">{currentStep.title}</span>
  {#if currentStep.step === Steps.WELCOME}
    <p class="text-md text-center md:text-left text-white md:text-gray-900 font-semibold">
      Richiedi un orientamento personalizzato
    </p>
  {:else if currentStep.step === Steps.FORM}
    <div class="apply-orientation">
      <ApplyOrientationForm
        bind:handleSubmit={applyOrientationForm} 
        bind:loading={loading}
        on:success={handleNextStep}   
      />
    </div>
  {:else if currentStep.step === Steps.THANKS}
    <p class="text-gray-900">
      Grazie dell’interesse mostrato per i nostri corsi. Saremo al tuo fianco per guidarti nella scelta più in linea con i tuoi obiettivi. Ti contatteremo entro 24 ore per fissare il tuo colloquio di orientamento.
    </p>
    {#if !isLastStepSubmitted}
      <div class="apply-orientation my-5">
        <CallPreferenceForm 
          bind:handleSubmit={applyOrientationCallPreferenceForm} 
          bind:loading={loading} 
          on:success={handleNextStep} 
        />
        <p class="col-span-full my-8">Vuoi darci qualche altra informazione per aiutare i nostri consulenti?</p>
        <div class="grid gap-4 grid-cols-2">
          <div>
            <Button
              variant="fill"
              on:click={() => handleButtonClick(false)}
              isLoading={loading}
              disabled={loading}
              fullWidth
            >
              No
            </Button>
          </div>
          <div>
            <Button
              variant="fill"
              on:click={() => handleButtonClick(true)}
              isLoading={loading}
              disabled={loading}
              fullWidth
            >
              Si
            </Button>
          </div>
        </div>
      </div>
    {:else}
      <p class="text-gray-900 my-5 font-semibold text-center">
        Preferenza confermata.
      </p>
    {/if}
  {/if}
</PopupModal>

<style type="text/scss" lang="scss" scoped>
  .apply-orientation {
    @apply w-full;
  }
</style>