<script lang="ts">
</script>

<div class="mega-links">
  <slot />
</div>

<style type="text/scss" lang="scss" scoped>
  .mega-links {
    @apply hidden;
    @apply lg:flex;
    @apply my-auto;
    z-index: 110;
  }

  :global(.mega-links > a) {
    @apply relative mx-2 px-0.5 py-1;
    @apply text-sm font-semibold uppercase;
    @apply tracking-widest;
    @apply lg:mx-3 lg:text-xs lg:leading-6;
    @apply xl:mx-6 xl:text-base;

    &:after {
      content: "";
      @apply absolute left-0 bottom-0;
      @apply h-0.5 bg-current w-full bg-opacity-30;
      @apply transform scale-x-0;
      @apply ease-in-out duration-200 origin-right;
      transition-property: transform;
    }
  }

  :global(.mega-links > a[aria-expanded]:after),
  :global(.mega-links > a:hover:after) {
    @apply transform scale-x-100;
    @apply origin-left;
  }
</style>
