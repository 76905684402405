<script lang="ts">
  export let title: string
  export let linkStyle: "default" | "title" | "heading" = "default"
</script>

<div
  class="panel-group"
  class:v-default="{linkStyle === 'default'}"
  class:v-title="{linkStyle === 'title'}"
  class:v-heading="{linkStyle === 'heading'}"
>
  {#if title}
    <div class="group-title fs-4 text-xl">{title}</div>
  {/if}
  <div class="group-links">
    <slot />
  </div>
</div>

<style type="text/scss" lang="scss" scoped>
  .panel-group {
    @apply flex flex-col;

    &.v-default {
      @apply border-b border-gray-900 border-opacity-20;
      @apply pb-6;
      @apply lg:border-b-0;
    }

    &.v-title {
      @apply lg:pb-6;
    }

    &.v-heading {
      @apply pb-6;
    }
  }

  .group-title:not([hidden]) {
    @apply text-xl;
    @apply mb-3;
    @apply lg:text-5xl;
    @apply lg:mb-5;
  }

  .group-links {
    @apply flex flex-col items-start;
  }

  :global(.group-links > a) {
    @apply relative;
    @apply text-md font-serif font-medium;
    @apply py-0.5;
    @apply transition-colors duration-100;
    @apply w-full break-words;
    @apply lg:break-normal;
    @apply lg:w-auto;

    @screen md {
      &:after {
        content: "";
        @apply absolute left-0 bottom-0;
        @apply h-0.5 bg-current w-full bg-opacity-30;
        @apply transform scale-x-0;
        @apply ease-in-out duration-200 origin-right;
        transition-property: transform;
      }

      &:hover:after {
        @apply transform scale-x-100;
        @apply origin-left;
      }
    }
  }

  :global(.group-links > p) {
    @apply relative;
    @apply text-md font-serif font-medium;
    @apply py-0.5;
    @apply w-full break-words;
    @apply lg:break-normal;
    @apply lg:w-auto;
  }

  :global(.group-links > ul > a > li) {
    @apply relative;
    @apply text-md font-serif font-medium;
    @apply py-0.5;
    @apply transition-colors duration-100;
    @apply w-full break-words;
    @apply lg:break-normal;
    @apply lg:w-auto;

    @screen md {
      &:after {
        content: "";
        @apply absolute left-0 bottom-0;
        @apply h-0.5 bg-current w-full bg-opacity-30;
        @apply transform scale-x-0;
        @apply ease-in-out duration-200 origin-right;
        transition-property: transform;
      }

      &:hover:after {
        @apply transform scale-x-100;
        @apply origin-left;
      }
    }
  }

  :global(.panel-group.v-title .group-links > a) {
    @apply text-md font-serif font-medium;
    @apply py-5;
    @apply lg:text-lg;
    @apply lg:py-0.5;
  }

  :global(.group-links > a + a) {
    @apply mt-2.5;
  }

  :global(.panel-group.v-title .group-links > a + a) {
    @apply mt-0 lg:mt-2.5;
    @apply border-t border-gray-900 border-opacity-20;
    @apply lg:border-t-0;
  }

  :global(.panel-group.v-heading .group-links > a + a) {
    @apply lg:mt-2.5;
  }
</style>
