<script lang="ts">
  import { onDestroy } from "svelte";
  import { goto } from "$app/navigation";
  // Stores
  import { AuthErrorStatus, useOktaStore } from "$lib/utils/auth-store";
  // Components
  import { Icon } from '$lib/components/atoms/Icon'
  import { Avatar } from '$lib/components/atoms/Avatar'
  import { LoginModal, PasswordRecoveryModal } from '$lib/components/organisms/Modal'
	import { openModal } from "$lib/utils/modals";
  
  const authStore = useOktaStore();
  let unlock = false;

  let onLogout = async () => {
    // perform the logout
    await authStore.signOut()
    goto("/");
  };

  const showPasswordRecoveryModal = () => {
    unlock = false;
    openModal(PasswordRecoveryModal, {
      unlock,
    });
  };

  const onAuthError = (event: CustomEvent<AuthErrorStatus>) => {
    if (event.detail == AuthErrorStatus.LOCKED_OUT) {
      unlock = true;
      openModal(PasswordRecoveryModal, {
        unlock,
      });
    }
  };

  const showLoginModal = () => {
    openModal(LoginModal, {
      onPasswordReset: showPasswordRecoveryModal,
      onAuthError,
    });
  };

  let isLoggedIn = authStore.isLoggedIn;
  let fullname = "";
  let unsubcribeFromUserInfo = authStore.userInfo.subscribe((ui) => {
    fullname = ui != null ? ui.fullname : "";
  });

  onDestroy(() => {
    unsubcribeFromUserInfo();
  });
</script>

{#if $isLoggedIn}
  <div class="navbar-account">
    <div class="avatar">
      <Avatar bind:fullname />
      <ul class="account-dropdown">
        <li>
          <a class="dropdown-link-item" href="/my-data">
            <span class="dropdown-link-text">Area Privata</span>
          </a>
        </li>
        <li>
          <a class="dropdown-link-item" href="https://elearning.24orebs.com/">
            <span class="dropdown-link-text">E-learning</span>
          </a>
        </li>
        <li>
          <a
            class="dropdown-link-item logout-link"
            href="/logout"
            on:click|preventDefault={onLogout}
          >
            <span class="dropdown-link-text">Esci</span>
            <span class="dropdown-link-icon"
              ><Icon icon="logout" size="small" /></span
            >
          </a>
        </li>
      </ul>
    </div>
  </div>
{:else}
  <div class="navbar-account">
    <div class="avatar">
      <div class="avatar-icon">
        <Icon icon="account" size="full" />
      </div>
      <ul class="account-dropdown">
        <li>
          <a
            class="dropdown-link-item"
            href="/login"
            on:click|preventDefault={showLoginModal}
          >
            <span class="dropdown-link-text">Accedi</span>
          </a>
        </li>
        <li>
          <a class="dropdown-link-item" href="/registrati">
            <span class="dropdown-link-text">Registrati</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>
  .navbar-account {
    --dropdown-width: 185px;
    @apply hidden;
    @apply border-l border-r border-gray-100;
    @apply px-4;
    @apply lg:flex lg:items-center;
    @apply lg:border-l-0;
    @apply lg:px-7;
    @apply z-infront-side-navbar;
  }

  .avatar-icon {
    @apply inline-flex text-gray-300;
    @apply  w-full h-full;
  }
  .avatar {
    @apply cursor-pointer;
    @apply relative;
    @apply w-6 h-6 rounded-full;
    @apply flex items-center justify-center;
    @apply lg:w-10 lg:h-10;

    /* Expand target area with pseudo-elements */
    &:after {
      content: "";
      @apply absolute -inset-5;
    }

    &:hover {
      .avatar-icon {
        @apply text-gray-900;
      }

      .account-dropdown {
        @apply visible;
        @apply opacity-100;
        @apply delay-0;
        --offset-animation: 0px;
      }
    }
  }

  .account-dropdown {
    --offset-animation: theme("spacing.2");
    @apply absolute right-0 -bottom-4;
    @apply bg-white shadow-card rounded;
    @apply invisible;
    @apply opacity-0;
    @apply ease-in-out duration-200 delay-300;
    transform: translateY(calc(100% - var(--offset-animation)));
    transition-property: transform, opacity, visibility;
    min-width: var(--dropdown-width);
  }

  .dropdown-link-text {
    @apply relative inline-block;
    &:after {
      content: "";
      @apply absolute left-0 bottom-0;
      @apply bg-black w-full;
      @apply transform scale-x-0;
      @apply ease-in-out duration-200 origin-right;
      height: 1px;
      transition-property: transform;
    }
  }

  .dropdown-link-item {
    @apply text-base;
    @apply cursor-pointer;
    @apply inline-block w-full;
    @apply px-4 py-2;
    @apply ease-in-out duration-200;

    &.logout-link {
      @apply uppercase tracking-wide font-semibold text-sm;
      @apply flex items-center;
      @apply border-gray-100 border-t;
      .dropdown-link-icon {
        @apply inline-flex ml-1;
      }
    }
    &:hover {
      .dropdown-link-text:after {
        @apply transform scale-x-100;
        @apply origin-left;
      }
    }
  }
</style>
