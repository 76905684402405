<script lang="ts">
  import { useCountdown } from '$lib/utils/countdown-store';
  // Components
  import Tracker from './Tracker.svelte';

  const countdownStore = useCountdown();
  const countdown = countdownStore.countdown;
</script>

{#if $countdown}
  <div class="timer-label v-stick-top">
    <div id="timer-label" class="text-white justify-center">
      <span>PREZZI SPECIALI ANCORA PER</span>
      <div class="flip-clock" data-date="2021-02-22">
        <Tracker property="days" />
        <Tracker property="hours" />
        <Tracker property="minutes" />
        <Tracker property="seconds" />
      </div>
    </div>
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>
  .flip-clock {
    text-align: center;
    perspective: 600px;
  }
  .timer-label {
    @apply z-over-sticky-element;
    position: sticky;
    top: var(--navbar-height);
    z-index: 80;

    &.v-stick-top {
      top: -1px;
    }
  }

  #timer-label {
    @apply bg-label-super_sconto;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    max-height: 100px;

    @screen md {
      justify-content: center;
      min-height: 120px;
      max-height: 120px;
    }
  }

  #timer-label span {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;

    @screen lg {
      font-size: 1.4rem;
    }
  }
</style>
