<script lang="ts">
  import { fade } from "svelte/transition"
  import { cubicInOut } from "svelte/easing"

  export let visible = false
  export let delay = 0
</script>

{#if visible}
  <div
    class="navigation-backdrop"
    in:fade="{{ duration: 300, delay: delay, easing: cubicInOut }}"
    out:fade="{{ duration: 300, delay: delay, easing: cubicInOut }}"
    on:click
  ></div>
{/if}

<style type="text/scss" lang="scss" scoped>
  .navigation-backdrop {
    @apply fixed inset-0;
    @apply bg-gray-900 bg-opacity-80;
    @apply -z-1;
    top: var(--navbar-height);
  }
</style>
