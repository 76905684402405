<script lang="ts">
  import classnames from "classnames";

  export let fullname;
  /** @type {'small'|'large'} */
  export let size = "large";

  export const getInitials = (name) =>
    name
      .match(/(\b\S)?/g)
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("")
      .toUpperCase();

  let initials;

  $: initials = getInitials(fullname);
</script>

<span
  class={classnames("avatar", {
    "s-small": size === "small",
    "s-large": size === "large",
  })}
>
  <span class="avatar-initials">{initials}</span>
</span>

<style type="text/scss" lang="scss" scoped>
  .avatar {
    @apply inline-flex items-center justify-center;
    @apply rounded-full;
    @apply bg-gray-900;
  }

  .avatar-initials {
    @apply font-medium leading-none text-white uppercase tracking-wide;
  }
  .s-large {
    @apply h-10 w-10;
    .avatar-initials {
      @apply text-sm;
    }
  }

  .s-small {
    @apply h-6 w-6;
    .avatar-initials {
      @apply text-xs;
    }
  }
</style>
