<script lang="ts" context="module">
  /**
   * The modal component is a reusable component that can be used to display popup modal.
   */

  import classNames from "classnames";
  import { createEventDispatcher } from "svelte";
	import { goto } from "$app/navigation";
  import { PopupUtils } from "$lib/utils/popup-utils";
  import { useOktaStore } from '$lib/utils/auth-store';
  import { useSessionStore } from '$lib/utils/session-store';
  import { Button } from "$lib/components/atoms/Button";

  export type PopupModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
</script>

<script lang="ts">
  /**
   * The noslide variable is used to determine if the modal is shown with slide (default) animation or not.
   * It is set to false by default.
   * @type {boolean}
   */
  export let noslide: boolean = false;

  /**
   * The modalActive variable is used to determine if the modal is active or not.
   * It is set to true by default.
   * @type {boolean}
   */
  export let modalActive: boolean = true;

  /**
   * The size variable is used to determine the size of the modal.
   * It is set to xs by default.
   * @type {string}
   */
  export let size: PopupModalSize = 'xs';

  /**
   * The loading variable is used to determine if the button is loading or not.
   * It is set to false by default.
   * @type {boolean}
   */
  export let loading: boolean = false;

  /**
   * The showButton variable is used to determine if the button is shown or not.
   * It is set to true by default.
   * @type {boolean}
   */
  export let showButton: boolean = true;
  $: if (showButton === null || showButton === undefined) showButton = true;

  /**
   * The buttonAlign variable is used to determine the alignment of the button.
   * @type {'left' | 'center' | 'right'}
   */
  export let buttonAlign: 'left' | 'center' | 'right' = 'center';
  $: if (buttonAlign === null || showButton === undefined) buttonAlign = 'center';

  /**
   * The buttonLabel variable is used to determine the label of the button.
   * @type {string}
   */
  export let buttonLabel: string;

  /**
   * The bgColourClass variable is used to determine the background colour of the modal.
   * @type {string}
   */
  export let bgColourClass: string = 'bg-white';

  /**
   * The titleColourClass variable is used to determine the title colour of the modal.
   * @type {string}
   */
  export let titleColourClass: string = 'text-gray-900';

  /**
   * the isWithRibbon variable is optional and is used to determine the popup behavior on close.
   * @type {boolean}
  */
  export let isWithRibbon: boolean = false;

  /**
   * the ribbonText variable is optional and is used to set the ribbon text.
   * @type {string | null}
   */
  export let ribbonText: string | null = null;

  // Create an event dispatcher
  const dispatch = createEventDispatcher();
  const authStore = useOktaStore();
  const sessionStore = useSessionStore();

  const isLoggedIn = authStore.isLoggedIn;
  const pendingUserInfo = sessionStore.pendingUserInfo;

  let showRibbon: boolean = PopupUtils.isPopupClosed();

  /**
   * The handleButtonClick function is used to handle the button click event.
   * It dispatches the buttonClick event.
   * @returns {void}
   */
  const handleButtonClick = () => {
    dispatch('buttonClick');
  };

  const handleMobileButtonClick = () => {
    if (!$isLoggedIn && !$pendingUserInfo) {
      goto(`/login?callback=orientamento`)
    } else {
      goto('/orientamento')
    }
  };

  /**
   * The handleRibbonClick function is used to handle the ribbon click event.
   * It dispatches the ribbonClick event.
   * @returns {void}
   */
  const handleRibbonClick = () => {
    PopupUtils.clearPopupClosed();
    showRibbon = false;
  };

  /**
   * The handleCloseClick function is used to handle the close click event.
   * It dispatches the closeClick event.
   * @returns {void}
   */
  const handleCloseClick = () => {
    if (isWithRibbon) {
      PopupUtils.setPopupClosed();
      showRibbon = true;
    } else {
      modalActive = false;
    }
  };
</script>

{#if showRibbon}
  <div class="popup-ribbon ribbon-oblique" on:click={handleRibbonClick}>{ribbonText}</div>
{/if}
<div
  class={classNames(
    'popup-modal',
    bgColourClass,
    {'md:max-w-xs': size === 'xs'},
    {'md:max-w-sm': size === 'sm'},
    {'md:max-w-md': size === 'md'},
    {'md:max-w-lg': size === 'lg'},
    {'md:max-w-xl': size === 'xl'},
    {'md:max-w-2xl': size === '2xl'},
    {'md:max-w-3xl': size === '3xl'},
    {'md:max-w-4xl': size === '4xl'},
    {'md:max-w-5xl': size === '5xl'},
    {'md:max-w-6xl': size === '6xl'},
    {'md:max-w-full': size === 'full'},
    {'pb-16': showButton},
    {'hidden': !modalActive},
    showRibbon ? 'ribbon-block': 'ribbon-hide',
  )}
  class:noslide={noslide}
>
  <div class={classNames('max-h-[70vh] overflow-y-auto px-6 py-3', bgColourClass)}>
    <!-- close modal button -->
    <div class="absolute top-0 right-0 opacity-40 hidden md:block">
      <Button
        variant="icon"
        color="grey"
        icon="chevronRight"
        on:click={handleCloseClick}
      />
    </div>
    <div class="absolute top-0 right-0 opacity-40 block md:hidden">
      <Button
        variant="icon"
        color="white"
        icon="close"
        on:click={handleCloseClick}
      />
    </div>
    <div class="w-full">
      <div class="mt-3 text-left">
        <h3 class={classNames('text-lg leading-6 font-medium', titleColourClass)} id="modal-title">
          <slot name="title" />
        </h3>
        <div class="mt-2">
          <slot />
        </div>
      </div>
    </div>
  </div>
  {#if showButton}
    <div
      class={classNames(
        'popup-modal-footer hidden md:block',
        bgColourClass,
        {'justify-start': buttonAlign === 'left'},
        {'justify-center': buttonAlign === 'center'},
        {'justify-end': buttonAlign === 'right'}
      )}
    >
      <Button
        variant="fill"
        on:click={handleButtonClick}
        isLoading={loading}
        disabled={loading}
        fullWidth
      >
        {buttonLabel}
      </Button>
    </div>
    <div
      class={classNames(
        'popup-modal-footer block md:hidden',
        bgColourClass,
        {'justify-start': buttonAlign === 'left'},
        {'justify-center': buttonAlign === 'center'},
        {'justify-end': buttonAlign === 'right'}
      )}
    >
      <Button
        variant="fill"
        on:click={handleMobileButtonClick}
        isLoading={loading}
        disabled={loading}
        fullWidth
      >
        {buttonLabel}
      </Button>
    </div>
  {/if}
</div>

<style scoped type="text/scss" lang="scss">
  .popup-modal {
    @apply inline-block;
    @apply fixed;
    @apply bottom-0 right-0;
    @apply m-0 md:m-4;
    @apply align-bottom sm:align-middle;
    @apply md:rounded-lg;
    @apply text-left;
    @apply overflow-hidden;
    @apply shadow-md;
    @apply md:border border-gray-600;
    @apply transform transition-all duration-500;
    @apply w-full;
    @apply max-h-[80vh];
    @apply z-[80];

    & > .popup-modal-footer {
      @apply flex;
      @apply p-2;
      @apply w-full h-16;
      @apply absolute;
      @apply bottom-0;
    }

    & > .noslide {
      transform: all 0s !important;
    }
  }

  .ribbon-block {
    @apply right-[-30rem];
  }
  .ribbon-hide {
    @apply right-0;
  }

  .popup-ribbon {
    @apply fixed;
    @apply left-auto top-auto right-[-3.125rem] bottom-[1.563rem];
    @apply cursor-pointer;
    @apply bg-brand transition-colors hover:bg-brand-dark;
    @apply text-center;
    @apply font-semibold;
    @apply leading-10;
    @apply tracking-wide;
    @apply shadow-sm;
    @apply transform transition-opacity duration-500;
    @apply z-[80];
  }

  .ribbon-vertical {
    @apply w-[8.5rem];
    @apply right-[-3rem] bottom-[4rem];
    @apply -rotate-90;
    @apply rounded-t-lg
  }

  .ribbon-oblique {
    @apply w-[12.5rem];
    @apply right-[-3.125rem] bottom-[1.563rem];
    @apply -rotate-45;
  }

</style>