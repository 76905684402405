<script lang="ts">
	import { onDestroy } from 'svelte';
	import { useCountdown } from '$lib/utils/countdown-store';

	export let property: string | null = null;

	const countdownStore = useCountdown();
	const countdown = countdownStore.countdown;

	let show = true;
	let current = $countdown[property];
	let previous = $countdown[property];
	let el: HTMLElement;

	const countdownUnsubscribe = countdownStore.countdown.subscribe((value) => {
		if (value[property] !== current) {
			previous = current;
			current = value[property];
			if (el) {
				el.classList.remove('flip');
				void el.offsetHeight;
				el.classList.add('flip');
			}
		}
	});

	onDestroy(() => {
		countdownUnsubscribe();
	});

	const texts = {
		days: { single: 'giorno', many: 'giorni' },
		hours: { single: 'ora', many: 'ore' },
		minutes: { single: 'minuto', many: 'minuti' },
		seconds: { single: 'secondo', many: 'secondi' }
	};
</script>

{#if show}
	<span bind:this={el} class="flip flip-clock__piece">
		<span class="flip-clock__card flip-card">
			<b class="flip-card__top">{(current < 10 && current > -1 ? '0' : '') + current || '00'}</b>
			<b
				class="flip-card__bottom"
				data-value={(current < 10 && current > -1 ? '0' : '') + current || '00'}
			/>
			<b
				class="flip-card__back"
				data-value={(previous < 10 && previous > -1 ? '0' : '') + previous || '00'}
			/>
			<b
				class="flip-card__back-bottom"
				data-value={(previous < 10 && previous > -1 ? '0' : '') + previous || '00'}
			/>
		</span>
		<span class="flip-clock__slot"
			>{current === 1 ? texts[property].single : texts[property].many}</span
		>
	</span>
{/if}

<style type="text/scss" lang="scss" scoped>
	.flip-clock__piece {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		margin: 0 0.8vw;
	}

	@media (min-width: 1000px) {
		.flip-clock__piece {
			margin: 0 10px;
		}
	}

	.flip-clock__slot {
		font-size: 1rem;
		line-height: 1.5;
		display: block;
	}

	.flip-card {
		display: block;
		position: relative;
		padding-bottom: 0.72em;
		font-size: 1.5rem;
		line-height: 0.95;
	}

	@media (min-width: 1000px) {
		.flip-clock__slot {
			font-size: 1rem;
		}
		.flip-card {
			font-size: 1.8rem;
		}
	}

	/*////////////////////////////////////////*/

  .flip-card__top,
  .flip-card__bottom,
  .flip-card__back-bottom,
  .flip-card__back::before,
  .flip-card__back::after {
    display: block;
    height: 0.72em;
    color: #ccc;
    background: #222;
    padding: 0.23em 0.25em 0.4em;
    border-radius: 0.15em 0.15em 0 0;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    width: 1.8em;
  }

	.flip-card__bottom,
	.flip-card__back-bottom {
		color: #fff;
		position: absolute;
		top: 50%;
		left: 0;
		border-top: solid 1px #000000;
		background: #393939;
		border-radius: 0 0 0.15em 0.15em;
		pointer-events: none;
		overflow: hidden;
		z-index: 2;
	}

	.flip-card__back-bottom {
		z-index: 1;
	}

	.flip-card__bottom::after,
	.flip-card__back-bottom::after {
		display: block;
		margin-top: -0.72em;
	}

	.flip-card__back::before,
	.flip-card__bottom::after,
	.flip-card__back-bottom::after {
		content: attr(data-value);
	}

	.flip-card__back {
		position: absolute;
		top: 0;
		height: 100%;
		left: 0%;
		pointer-events: none;
	}

	.flip-card__back::before {
		position: relative;
		overflow: hidden;
		z-index: -1;
	}

	.flip .flip-card__back::before {
		z-index: 1;
		animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
		animation-fill-mode: both;
		transform-origin: center bottom;
	}

	.flip .flip-card__bottom {
		transform-origin: center top;
		animation-fill-mode: both;
		animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
	}

	@keyframes flipTop {
		0% {
			transform: rotateX(0deg);
			z-index: 2;
		}
		0%,
		99% {
			opacity: 1;
		}
		100% {
			transform: rotateX(-90deg);
			opacity: 0;
		}
	}

	@keyframes flipBottom {
		0%,
		50% {
			z-index: -1;
			transform: rotateX(90deg);
			opacity: 0;
		}
		51% {
			opacity: 1;
		}
		100% {
			opacity: 1;
			transform: rotateX(0deg);
			z-index: 5;
		}
	}
</style>
