<script lang="ts">
  // Components
  import { Icon } from '$lib/components/atoms/Icon';

  export let current
</script>

<a class="navbar-search" href="#/" on:click|preventDefault aria-current="{current ? '' : undefined}">
  <div class="search-icon">
    <Icon icon="search" size="full" />
  </div>
</a>

<style type="text/scss" lang="scss" scoped>
  .navbar-search {
    @apply flex items-center relative;
    @apply border-l border-r border-gray-100;
    @apply px-4;
    @apply lg:border-l-0;
    @apply lg:px-7;

    &[aria-current] {
      .search-icon {
        @apply text-brand;
      }
    }
  }

  .search-icon {
    @apply w-6 h-6;
    @apply lg:w-8 lg:h-8;
  }
</style>
