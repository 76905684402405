<script lang="ts">
  // Components
  import { Grid } from '$lib/components/organisms/Layout'
  
  export let title: string
  export let colsCount: "one" | "two" | "three" | "four" | "five" = "four"
</script>

<div class="mega-panel">
  <Grid class="mega-panel-grid">
    <div class="panel-title subtitle v-small">{title}</div>

    <div class="panel-content" class:v-two-cols="{colsCount === 'two'}" class:v-three-cols={colsCount === 'three'} class:v-four-cols="{colsCount === 'four'}" class:v-five-cols="{colsCount === 'five'}">
      <slot />
    </div>

    {#if $$slots["sublink"]}
      <div class="panel-sublink">
        <slot name="sublink" />
      </div>
    {/if}

    {#if $$slots["profiler"]}
      <div class="panel-profiler">
        <slot name="profiler" />
      </div>
    {/if}
  </Grid>
</div>

<style type="text/scss" lang="scss" scoped>
  .mega-panel {
    .panel-title:not([hidden]) {
      @apply col-span-full;
      @apply text-sm lg:text-sm;
    }
  }

  :global(.mega-panel-grid) {
    @apply relative;
  }

  .panel-content {
    @apply col-span-full;
    @apply grid grid-cols-1 gap-6;
    @apply lg:mb-12;

    &.v-two-cols {
      @apply lg:grid-cols-2;
    }

    &.v-three-cols {
      @apply lg:grid-cols-3;
    }
    &.v-four-cols {
      @apply lg:grid-cols-4;
    }
    &.v-five-cols {
      @apply lg:grid-cols-5;
    }
  }

  .panel-sublink {
    @apply mb-4;
    @apply lg:my-4;
  }

  .panel-profiler {
    @apply absolute bottom-0 right-0;
    @apply w-1/3 min-w-max;
    @apply pr-10 mr-12 -mb-7;
    @apply transform translate-y-1/2;
  }
</style>
