<script lang="ts">
  import { SearchFilterStore } from "$lib/utils/searchfilter-store";
  import { SearchQuickLinks } from ".";

  const {history,currentTyped} = SearchFilterStore.getInstance(fetch);

  const frequentSearches = [...$history.searches].reverse().slice(1,4);
</script>

{#if (!$currentTyped && frequentSearches && frequentSearches.length>0)}
  <SearchQuickLinks title="Ultime ricerche" >
    {#each frequentSearches as historyRow, index}
      <a href="/esplora?q={historyRow}">{historyRow}</a>
    {/each}
  </SearchQuickLinks>
{/if}