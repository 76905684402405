<script lang="ts">
  import { goto } from "$app/navigation"
  // Utils
  import { pageOptionsStore } from "$lib/utils/layout-store"
  // Components
  import { Button } from "$lib/components/atoms/Button"

  const onClose = async () => {
    const modalCloseCallback = $pageOptionsStore.modalCloseCallback

    if (modalCloseCallback) {
      const canClose = await modalCloseCallback()

      if (!canClose) return
    }

    if ($pageOptionsStore.referrer || document.referrer.includes(window.location.origin)) {
      history.back()
    } else {
      goto("/")
    }
  }
</script>

<div class="navbar-modal-back">
  <Button variant="icon" color="grey" icon="close" on:click="{onClose}" />
</div>

<style type="text/scss" lang="scss" scoped>
  .navbar-modal-back {
    @apply flex items-center;
  }
</style>
