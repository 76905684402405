<script lang="ts">
  export let open = false;
  export let noVerticalPadding = false;
  export let showOnMobile = false;
</script>

<div
  class="mega-menu"
  class:open
  class:v-no-padding={noVerticalPadding}
  class:v-show-mobile={showOnMobile}
>
  {#if $$slots["header"]}
    <div class="mega-menu-header">
      <slot name="header" />
    </div>
  {/if}

  {#if $$slots["default"]}
    <div class="mega-menu-content">
      <slot />
    </div>
  {/if}

  {#if $$slots["footer"]}
    <div on:scroll={(e) => e.stopPropagation()} class="mega-menu-footer">
      <slot name="footer" />
    </div>
  {/if}
</div>

<style lang="scss" scoped>
  .mega-menu {
    @apply hidden relative;
    @apply flex-col fixed inset-x-0;
    @apply bg-gray-50;
    @apply py-7 z-0;
    @apply h-0;
    top: var(--navbar-height);
    max-height: calc(100vh - var(--navbar-height));

    transform: translateY(0px);
    transition:
      transform 300ms ease-in-out,
      z-index 300ms ease;
    z-index: -100;

    &.v-no-padding {
      @apply py-0;
    }

    &.v-show-mobile {
      @apply flex;
    }
  }

  .mega-menu.open {
    // @apply z-side-navbar;
    @apply z-50;
    @apply min-h-[460px];
    @apply h-auto;
    @apply lg:flex;
    transform: translateY(0px);
  }

  .mega-menu.v-show-mobile:not(.open) {
    @apply hidden;
  }

  .mega-menu-content {
    @apply flex-grow;
  }

  .mega-menu-header,
  .mega-menu-footer {
    @apply flex-1 flex flex-col;
  }

  .mega-menu-footer {
    @apply overflow-y-auto;
  }
</style>
