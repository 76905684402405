<script lang="ts">
  import { createEventDispatcher } from "svelte";
  // Components
  import { Icon } from '$lib/components/atoms/Icon';
  import { SideNavbarMainNav, SocialNav } from '$lib/components/organisms/Navigation';
  
  export let visible = false;

  const dispatch = createEventDispatcher();
  const onToggleVisibility = () => {
    dispatch("togglevisibility");
  };
</script>

<nav class="side-navbar" class:visible>
  <section class="side-navbar-panel">
    <div class="side-navbar-content">
      <div class="main-menu">
        <SideNavbarMainNav />
      </div>
      <div class="social-menu">
        <SocialNav />
      </div>
    </div>
    <div class="side-navbar-close">
      <button class="side-navbar-close-btn" on:click={onToggleVisibility}
        ><Icon icon="close" size="full" /></button
      >
    </div>
  </section>
</nav>

<div
  on:click={onToggleVisibility}
  class="side-navbar-backdrop"
  class:visible
></div>

<style type="text/scss" lang="scss" scoped>
  .side-navbar {
    --panel-width: 600px;
    @apply hidden;
    @apply lg:fixed lg:inset-y-0 lg:right-0;
    @apply lg:h-screen;
    @apply lg:flex justify-end;
    transform: translateX(var(--panel-width));
    transition:
      transform 700ms ease-in-out,
      z-index 300ms ease;
    z-index: -100;
  }

  .side-navbar-panel {
    --panel-width: 600px;
    @apply min-h-screen;
    @apply flex;
    @apply bg-brand;
    width: var(--panel-width);
  }

  .side-navbar-backdrop {
    @apply hidden;
    @apply lg:block lg:fixed lg:inset-0;
    @apply bg-gray-900 bg-opacity-75;
    @apply opacity-0;
    @apply translate-x-[100vw];
    transition:
      opacity 300ms ease-in-out,
      z-index 300ms ease;
      transform: 300ms ease 300ms;
    z-index: -100;
  }

  .side-navbar.visible {
    @apply z-side-navbar;
    transform: translateX(0);
  }

  .side-navbar-backdrop.visible {
    @apply opacity-100;
    @apply translate-x-0;
    z-index: 700;
  }
  .side-navbar-close {
    @apply absolute top-0;
    @apply flex items-center justify-center;
    height: var(--navbar-height);
    right: var(--page-margin);
  }
  .side-navbar-close-btn {
    @apply relative;
    @apply inline-flex;
    @apply w-6 h-6;
    @apply lg:w-8 lg:h-8;

    &:after {
      content: "";
      @apply absolute -inset-2;
      @apply -z-1;
      @apply rounded-full;
      @apply opacity-50;
      @apply ease-in-out duration-200;
      transition-property: background-color;
    }

    &:focus {
      @apply outline-none;
      &:after {
        @apply bg-white;
      }
    }
  }

  /* Panel content */
  .side-navbar-content {
    @apply grid;
    @apply ml-28 my-10;
    grid-template-rows: 1fr auto;
  }

  .main-menu {
    @apply flex items-center;
  }
</style>
