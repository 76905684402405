<script lang="ts">
	import { onDestroy } from 'svelte';
	import { goto } from '$app/navigation';
	// Stores
	import { useOktaStore } from '$lib/utils/auth-store';
	// Components
	import { Icon } from '$lib/components/atoms/Icon';
	import { Avatar } from '$lib/components/atoms/Avatar';

	const authStore = useOktaStore();

	let loggedIn = authStore.isLoggedIn;
	let fullname = '';
	let unsuscribeFromUserInfo = authStore.userInfo.subscribe((ui) => {
		fullname = ui != null ? ui.fullname : '';
	});

	onDestroy(() => {
		unsuscribeFromUserInfo();
	});

	const onLogout = () => {
		// perform the logout
		authStore.signOut().then(() => {
			goto('/');
		});
	};
</script>

{#if $loggedIn}
	<div class="account-card account-logged">
		<a class="account-link" href="/my-data">
			<span class="account-icon"><Avatar bind:fullname size="small" /></span>
			<span class="account-link-text">Area privata</span>
		</a>
		<a class="account-link account-logout" href="/logout" on:click|preventDefault={onLogout}>
			<span class="account-icon"><Icon icon="logout" /></span>
			<span class="account-link-text">Logout</span>
		</a>
	</div>
{:else}
	<div class="account-card account-not-logged">
		<a class="account-link" href="/login">
			<span class="account-icon"><Icon icon="account" size="default" /></span>
			<span class="account-link-text">Accedi</span>
		</a>
	</div>
{/if}

<style type="text/scss" lang="scss" scoped>
	.account-card {
		@apply bg-white;
		@apply page-margin py-2;
	}

	.account-link {
		@apply flex items-center;
		@apply py-2;
		@apply text-md font-normal text-gray-900;
	}
	.account-icon {
		@apply inline-flex;
		@apply mr-3;
		@apply text-gray-300;
	}
	.account-not-logged {
		.account-icon {
			@apply text-gray-300;
		}
	}

	.account-logged {
		@apply grid grid-cols-2;
	}
	.account-logout {
		@apply pl-4;
		@apply border-l border-gray-900 border-opacity-20;
	}
</style>
