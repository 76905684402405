<script lang="ts">
  import { onMount } from 'svelte';
  import { fly } from 'svelte/transition';
  import { detectRobot } from "$lib/utils/browser-utils";
  // Stores
  import { useCookiesStore } from '$lib/utils/cookie-store';
  import { GtmStore } from '$lib/utils/gtm-store';
  // Components
  import { Accordion } from '$lib/components/atoms/Accordion';
  import { Button } from '$lib/components/atoms/Button';
  import { SwitchToggle } from '$lib/components/atoms/Field';
  // import { Modal } from '$lib/components/organisms/Modal';

  let open = false;
  let visible = false;
  let element: HTMLElement = null;

  let cookieNecessary: boolean = true;
  let cookieAnalytics: boolean = true;
  let cookieMarketing: boolean = false;
  
  onMount(() => {
     //Add this to avoid browser testig apps to show cookie bar
    if (
      typeof window !== 'undefined' &&
      typeof navigator !== 'undefined' &&
      navigator.userAgent &&
      !detectRobot(navigator.userAgent, true)
    ) {

      visible = true;
      
    }
  });

  const cookiesNecessary = [
    {
      name: "_gac_UA-48441541-10",
      from: "Google Analytics",
      desc: "Permettono di svolgere analisi sul comportamento o altri aspetti dell’utente, ma in maniera anonimizzata/pseudonimizzata e/o in forma aggregata. Questi cookies non sono usati per finalità di natura commerciale.",
      period: "2 anni"
    },
    {
      name: "static_assets",
      from: "Sito Web",
      desc: "Preferenza dominio di caricamento contenuti",
      period: "1 giorno"
    },
    {
      name: "cookie_notice_accepted",
      from: "Sito Web",
      desc: "Preferenza stato accettazione cookie policy",
      period: "30 giorni"
    },
    {
      name: "ab_session",
      from: "Sito Web",
      desc: "Preferenza del tipo di visualizzazione delle liste prodotti",
      period: "30 giorni"
    },
    {
      name: "ab_monitor",
      from: "Sito Web",
      desc: "Preferenza del tipo di visualizzazione delle liste prodotti",
      period: "30 giorni"
    },
    {
      name: "credit_filter",
      from: "Sito Web",
      desc: "Nessuna funzionalita' / Uso futuro",
      period: "10 giorni"
    },
    {
      name: "24orev",
      from: "Sito Web",
      desc: "Preferenza versione sito web visualizzata",
      period: "2 giorni"
    },
    {
      name: "SSO24",
      from: "Login App",
      desc: "Stato login utente",
      period: "Sessione"
    },
    {
      name: "DT",
      from: "Login App",
      desc: "Stato login utente",
      period: "Sessione"
    },
    {
      name: "sid",
      from: "Login App",
      desc: "Stato login utente",
      period: "Sessione"
    },
    {
      name: "JSESSIONID",
      from: "Login App",
      desc: "Stato login utente",
      period: "Sessione"
    },
    {
      name: "t",
      from: "Login App",
      desc: "Stato login utente",
      period: "Sessione"
    },
  ];

  const cookiesAnalytics = [
    {
      name: "_shopify_y",
      from: "Shopify",
      desc: "Shopify analytics",
      period: "1 anno"
    }
  ];

  const cookiesMarketing = [
    {
      name: "_uetsid, _uetvid",
      from: "Bing Microsoft",
      desc: "Questo cookie è utilizzato da Microsoft Bing Ads ed è un cookie di tracciamento. Ci consente di interagire con un utente che ha già visitato in precedenza il nostro sito web. Scade 30 minuti dopo il termine della sessione del browser. Per maggiori informazioni, visita https://advertise.bingads.microsoft.com/en-us/resources/policies/remarketing-in-paid-search-policies.",
      period: "30 min"
    },
    {
      name: "MUID, MUIDB",
      from: "Bing Microsoft",
      desc: "Identificano i singoli Web browser che visitano i siti Microsoft. Vengono utilizzati a fini pubblicitari, per l'analisi dei siti e per altri scopi operativi.",
      period: "1 anno"
    },
    {
      name: "_fbp, _fbc",
      from: "Facebook",
      desc: "Identifica i browser al fine di fornire servizi pubblicitari o di analisi dei dati del sito.",
      period: "90 giorni"
    },
    {
      name: "Linkedin",
      from: "Linkedin",
      desc: "TBD",
      period: "TBD"
    }
  ]

  const cookiesStore = useCookiesStore();

  const onSave = () => {
    const __profilation_cookie_USER_SELECTION__: string = cookieMarketing ? 'granted' : 'denied';

    open = false;
    visible = false;

    GtmStore.pushCookieBarEvent('granted', 'granted', __profilation_cookie_USER_SELECTION__);
  };

  const onClick = (cookieConset: string) => {

    cookiesStore.setCookies({
      cookie_notice_accepted: true,
    });
    visible = false;
    
    GtmStore.pushCookieBarEvent('granted', 'granted', cookieConset);
  };
</script>

{#if visible}
  <!-- <Modal bind:this={element} bind:open on:close="{() => (open = false)}" full showCloseButton centerContent>
    <span slot="title">Personalizza</span>
    <Accordion invertOrder="true" variant="dark" disableScroll>
      <div slot="question">
        <div class="flex justify-between">
          <span>Cookie strettamente necessari </span>
          <SwitchToggle bind:value={cookieNecessary} hiddenValue disabled/>
        </div>
      </div>
      <div slot="answer">
          <div class="cookies-table">
            <table class="w-full">
              <thead>
                <tr>
                    <th scope="col" class="p-2">
                      Nome
                    </th>
                    <th scope="col" class="p-2">
                      Fornitore
                    </th>
                    <th scope="col" class="p-2">
                      Finalità del trattamento
                    </th>
                    <th scope="col" class="p-2">
                      Scadenza
                    </th>
                </tr>
              </thead>
              <tbody>
                {#each cookiesNecessary as cookie}
                <tr class="rounded-lg">
                    <td class="p-2 whitespace-nowrap">
                      {cookie.name}
                    </td>
                    <td class="p-2 whitespace-nowrap">
                      {cookie.from}
                    </td>
                    <td class="p-2">
                      {cookie.desc}
                    </td>
                    <td class="p-2 whitespace-nowrap">
                      {cookie.period}
                    </td>
                </tr>
                {/each}
              </tbody>
            </table>
          </div>
      </div>
    </Accordion>
    <Accordion invertOrder="true" variant="dark" disableScroll>
      <div slot="question">
        <div class="flex justify-between">
          <span>Cookie analitici </span>
          <SwitchToggle bind:value={cookieAnalytics} hiddenValue disabled/>
        </div>
      </div>
      <div slot="answer">
        <div class="cookies-table">
          <table class="w-full">
            <thead>
              <tr>
                  <th scope="col" class="p-2">
                    Nome
                  </th>
                  <th scope="col" class="p-2">
                    Fornitore
                  </th>
                  <th scope="col" class="p-2">
                    Finalità del trattamento
                  </th>
                  <th scope="col" class="p-2">
                    Scadenza
                  </th>
              </tr>
            </thead>
            <tbody>
              {#each cookiesAnalytics as cookie}
              <tr class="rounded-lg">
                  <td class="p-2 whitespace-nowrap">
                    {cookie.name}
                  </td>
                  <td class="p-2 whitespace-nowrap">
                    {cookie.from}
                  </td>
                  <td class="p-2">
                    {cookie.desc}
                  </td>
                  <td class="p-2 whitespace-nowrap">
                    {cookie.period}
                  </td>
              </tr>
              {/each}
            </tbody>
          </table>
        </div>
      </div>
    </Accordion>
    <Accordion invertOrder="true" variant="dark" disableScroll>
      <div slot="question">
        <div class="flex justify-between">
          <span>Cookie di profilazione </span>
          <SwitchToggle bind:value={cookieMarketing} hiddenValue/>
        </div>
      </div>
      <div slot="answer">
        <div class="cookies-table">
          <table class="w-full">
            <thead>
              <tr>
                  <th scope="col" class="p-2">
                    Nome
                  </th>
                  <th scope="col" class="p-2">
                    Fornitore
                  </th>
                  <th scope="col" class="p-2">
                    Finalità del trattamento
                  </th>
                  <th scope="col" class="p-2">
                    Scadenza
                  </th>
              </tr>
            </thead>
            <tbody>
              {#each cookiesMarketing as cookie}
              <tr class="rounded-lg">
                  <td class="p-2 whitespace-nowrap">
                    {cookie.name}
                  </td>
                  <td class="p-2 whitespace-nowrap">
                    {cookie.from}
                  </td>
                  <td class="p-2">
                    {cookie.desc}
                  </td>
                  <td class="p-2 whitespace-nowrap">
                    {cookie.period}
                  </td>
              </tr>
              {/each}
            </tbody>
          </table>
        </div>
      </div>
    </Accordion>
    <svelte:fragment slot="actions">
      <div class="flex items-center justify-center flex-col md:flex-row">
        <Button compact class="mb-2 md:mb-0 mx-2" on:click={() => {open = false}} color="white">Annulla</Button>
        <Button compact class="mx-2" on:click={onSave} color="pink">Salva</Button>
      </div>
    </svelte:fragment>
  </Modal> -->
  <!-- in:fly={{ y: 100, duration: 500, delay: 4500 }} -->
  <div
    in:fly={{ y: 100, duration: 0, delay: 0 }}
    out:fly={{ y: 200, duration: 1000, delay: 50 }}
    class="cookie-bar"
  >
    <p class="text-left">
      Digit’Ed S.p.A. utilizza cookie tecnici, analitici e di profilazione, anche di terze parti. <br/>
      Questi ultimi saranno utilizzati solo a seguito di esplicito consenso in sede di personalizzazione di cookie, disponibile cliccando sul pulsante «Personalizza» o a seguito della compilazione di specifici form, disponibili sul sito. <br/>
      Per maggiori informazioni leggi la <a href="/cookie-policy" target="_blank">cookie policy</a> e/o le informative privacy presenti in ciascun form. <br/>
      Con la chiusura del presente banner saranno mantenute le impostazioni predefinite che non consentono l’utilizzo di cookie o di altri strumenti di tracciamento diversi da quelli tecnici.
    </p>
    <div class="flex items-center flex-col md:flex-row">
      <Button class="mb-2 md:mb-0 mx-2" on:click={() => onClick("denied")} color="white">Rifiuta</Button>
      <Button class="mb-2 md:mb-0 mx-2" on:click={() => {open = true}} color="white">PERSONALIZZA</Button>
      <Button class="mx-2" on:click={() => onClick("granted")} color="pink">Accetta</Button>
    </div>
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>
  .cookie-bar {
    @apply border-t border-brand-dark;
    @apply fixed bottom-0 right-0 left-0;
    @apply flex flex-col md:flex-row justify-end;
    @apply p-2 shadow font-medium;
    @apply bg-white;
    z-index: 100;
  }

  p {
    @apply text-center text-gray-900;
    @apply text-sm md:text-sm;
  }

  .button {
    @apply px-2 py-1 ml-2;
    @apply text-sm text-center no-underline tracking-wide;
    @apply rounded border border-transparent border-solid;
    @apply bg-brand border-brand text-gray-900 font-bold;
    @apply transition-colors hover:bg-brand-dark hover:border-brand-dark;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand focus:ring-opacity-30;
    @apply transition-colors ease-in-out duration-300;
  }

  tr:nth-child(even) {
    @apply bg-gray-100;
  }

  tr td:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
  } 

  tr td:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  .cookies-table {
    @apply max-h-80 overflow-auto md:overflow-x-hidden md:overflow-y-auto
  }
</style>
