<script lang="ts">
  // Components
  import { NavbarLogo } from '$lib/components/atoms/NavbarLogo';
  import { Grid } from '$lib/components/organisms/Layout';
  import { SocialNav } from '$lib/components/organisms/Navigation';
  import { AccreditamentiModal, PressOfficeModal } from '$lib/components/organisms/Modal/';
	import { openModal } from "$lib/utils/modals";
</script>

<footer class="footer">
  <Grid>
    <section class="company-info">
      <NavbarLogo light />
      <p class="font-serif text-base md:text-lg">Dal 1994, verso il futuro</p>
      <div class="social-links">
        <SocialNav />
      </div>
    </section>
    <section class="footer-navigation-1st-col">
      <ul class="footer-main-nav">
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/about">Chi siamo</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/contact-us">Contatti</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/sedi">Sedi</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/finanziamenti">Finanziamenti</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/news">News</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/faq">FAQ</a>
        </li>
        <!-- Temporary hide link to reviews
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/reviews">Review</a>
        </li>
        -->
      </ul>
    </section>
    <section class="footer-navigation-2st-col">
      <ul class="footer-main-nav">
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/eventi">Iniziative</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/eventi/open-day-open-dreams-live-streaming-2021-09-08">Open Day</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/docenti">Docenti</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/partners">Partners</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/b2b">Servizi per le imprese</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link cursor-pointer" on:click={() => openModal(AccreditamentiModal)}>Accreditamenti</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/diritto-di-recesso">Diritto di recesso</a>
        </li>
        <li class="footer-nav-item">
          <a class="footer-nav-item-link cursor-pointer" on:click={() => openModal(PressOfficeModal)}>Press Office</a>
        </li>
      </ul>
    </section>
    <!-- section class="footer-navigation-3st-col">
      <ul class="footer-main-nav">
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/percorsi-e-tematiche">Percorsi & Tematiche</a>
        </li>
      </ul>
    </section -->
  </Grid>
  <Grid noGrid>
    <div class="container lg-pb-0">
      <div class="lg:flex lg:justify-between mt-5">
        <div class="flex items-center">
          <a href="https://www.digited.it/" target="_blank">
            <img src="/assets/images/digited.png" class="h-10 w-auto" alt="Digit'Ed" />
          </a>
        </div>
      </div>
      <p class="italic mb-5">il più grande polo di formazione in Italia, leader nell’innovazione dell'apprendimento</p>
      <hr class="border-white mx-auto"/>
      <div class="text-justify text-sm mt-5">
        Digit’Ed S.p.A. a Socio unico - Sede legale: Via San Vigilio 1 - 20142 Milano C.F. e iscrizione al Registro delle Imprese di Milano Monza Brianza Lodi n. 00902000769REA MI-1948007 | P.I. 07490560633 - Capitale sociale pari a Euro 774.600,00 i.v. Soggetta a direzione e coordinamento di Digit’Ed Holding S.p.A. - Tutti i diritti riservati
      </div>
      <div class="item-container">
        <a href="/privacy-policy" class="item">
          Privacy policy
        </a>
        <a href="/cookie-policy" class="item">
          Cookie policy
        </a>
        <a href="/assets/documents/Codice-Etico.pdf" target="_blank" class="item">
          Codice etico
        </a>
        <a href="/assets/documents/Politica-per-la-Qualita.pdf" target="_blank" class="item">
          Politiche per la qualità
        </a>
        <a href="/assets/documents/Modello-231.pdf" target="_blank" class="item">
          Modello 231
        </a>
        <a href="https://www.linkedin.com/company/digit-ed/" target="_blank" class="item">
          Linkedin
        </a>
        <a href="/whistleblowing" class="item">
          Whistleblowing
        </a>
        <a href="/certificazioni-ISO" class="item">
          Certificazioni ISO
        </a>
        <a href="/assets/documents/Policy-ESG.pdf" target="_blank" class="item">
          Policy ESG
        </a>
        <a href="/trasparenza" class="item">
          Trasparenza
        </a>
        <a href="/inforisk" class="item">
          Inforisk
        </a>
        <a href="/mappa-del-sito" class="item">
          Mappa del sito
        </a>
      </div>
    </div>
  </Grid>

  <!-- <Modal open={openFooterModal} 
    on:close={() => {openFooterModal = false; showAccreditamenti = false; showPressOffice = false;}} 
    showCloseButton={true} 
    compact={true}>
    <section>
      {#if showAccreditamenti}
        <p>Digit’Ed S.p.A. è iscritto all'Albo dei soggetti accreditati per i Servizi di Istruzione e Formazione Professionale presso la Regione Lombardia (iscrizione n° 1412), presso la Regione Lazio (det. dir. n. G13562) e presso la Regione Campania (codice domanda: 340-1-7)</p>
      {/if}
      {#if showPressOffice}
        <p>Pinkommunication S.r.l.</p>
        <p>Via Monte Rosa, 67</p>
        <p class="mb-5">20149 Milano</p>
        <p>Tel: +39 0289077160</p>
        <p>Email: info@pinkommunication.it</p>
      {/if}
    </section>
  </Modal> -->
</footer>

<style type="text/scss" lang="scss" scoped>
  .footer {
    @apply py-8 md:py-10;
    @apply bg-gray-900;
    @apply text-white;
  }

  .company-info {
    @apply col-span-full md:col-span-4;
  }

  .social-links {
    @apply mt-10 md:mt-12;
  }

  .footer-navigation-1st-col {
    @apply col-span-full md:col-span-3 lg:col-span-2;
  }

  .footer-navigation-2st-col {
    @apply col-span-full md:col-span-2;
  }

  .footer-navigation-3st-col {
    @apply col-span-full md:col-span-3 lg:col-span-4;
  }

  .footer-navigation-1st-col,
  .footer-navigation-2st-col,
  .footer-navigation-3st-col {
    @apply pt-grid-default border-t border-white border-opacity-30;
    @apply md:pt-0 md:border-t-0;
  }

  .footer-main-nav .footer-nav-item + .footer-nav-item {
    @apply mt-3;
  }

  .footer-nav-item-link {
    @apply relative;
    @apply text-md text-white font-serif font-medium;

    &:after {
      content: "";
      @apply lg:absolute lg:left-0 lg:bottom-0.5;
      @apply lg:h-0.5 lg:bg-white lg:w-full lg:bg-opacity-30;
      @apply lg:transform lg:scale-x-0;
      @apply lg:ease-in-out lg:duration-200 lg:origin-right;
      transition-property: transform;
    }

    &:hover:after {
      @apply lg:transform lg:scale-x-100;
      @apply lg:origin-left;
    }
  }

  .legal-pages {
    @apply space-x-6;
    @apply flex;
  }

  .legal-pages-link {
    @apply hover:underline;
  }
  .item-container {
    @apply flex flex-col;
    @apply space-y-2;
    @apply md:flex-row; 
    @apply md:space-y-0 md:space-x-4;
    @apply my-5;
  }
  .item {
    @apply relative;
    @apply flex items-center;
    @apply gap-x-3;
    @apply text-sm leading-4;
    @apply font-normal;
    @apply no-underline;
    @apply hover:underline;
  }
</style>
