<script lang="ts">
  import classnames from "classnames"
  import { fade } from "svelte/transition"
  // Components
  import { Icon } from '$lib/components/atoms/Icon';

  export let displayOn: "mobile" | "desktop" = "mobile"
  export let isToggled = false
</script>

<button
  class="{classnames('navbar-toggle', {
    hidden: displayOn === 'desktop',
    'lg:hidden': displayOn === 'mobile',
    'lg:flex': displayOn === 'desktop',
  })}"
  type="button"
  aria-controls="navbarMenu"
  aria-expanded="false"
  aria-label="Mostra menu"
  on:click
>
  <div class="toggle-icon-wrapper">
    {#if isToggled}
      <div class="toggle-icon" in:fade="{{ duration: 100 }}" out:fade="{{ duration: 200 }}">
        <Icon icon="close" size="full" />
      </div>
    {:else}
      <div class="toggle-icon" in:fade="{{ duration: 100 }}" out:fade="{{ duration: 100 }}">
        <Icon icon="menu" size="full" />
      </div>
    {/if}
  </div>
</button>

<style type="text/scss" lang="scss" scoped>  .navbar-toggle {
    @apply items-center justify-center;
    @apply px-4 -mr-4;
    @apply lg:px-7 lg:-mr-7;
    @apply ease-in-out duration-200;
    transition-property: background-color;

    &:focus {
      @apply outline-none;
      @apply bg-gray-50;
    }
  }

  .toggle-icon-wrapper {
    @apply grid grid-cols-1 grid-rows-1;
  }

  .toggle-icon {
    @apply col-start-1 row-start-1;
    @apply w-6 h-6;
    @apply lg:w-8 lg:h-8;
  }
</style>
