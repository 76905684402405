<script lang="ts">
	import { modals } from "$lib/utils/modals";
	import { exitBeforeEnter, transitioning } from "$lib/utils/modals/store";

  function isLazyModal(component: any) {
    return typeof component.prototype === 'undefined'
  }

  async function getComponent(component: any) {
    return component().then((res: any) => res.default)
  }
</script>

{#if $modals.length > 0}
  <slot name="backdrop" />
{/if}

<slot>
  {#each $modals as modal, i (i)}
    <!-- lazy modal -->
    {#if isLazyModal(modal.component)}
      {#await getComponent(modal.component)}
        <slot name="loading" />
      {:then component}
        <svelte:component
          this={component}
          isOpen={i === $modals.length - 1 && !$transitioning}
          {...modal.props}
          on:introstart={() => {
            $exitBeforeEnter = true
          }}
          on:outroend={() => {
            $transitioning = false
          }}
          on:click
          on:keydown
          on:change
          on:submit
        />
      {/await}
    {:else}
      <!-- normal modal -->
      <svelte:component
        this={modal.component}
        isOpen={i === $modals.length - 1 && !$transitioning}
        {...modal.props}
        on:introstart={() => {
          $exitBeforeEnter = true
        }}
        on:outroend={() => {
          $transitioning = false
        }}
        on:click
        on:keydown
        on:change
        on:submit
      />
    {/if}
  {/each}
</slot>

<style type="text/scss" lang="scss" scoped>
  :global(.backdrop) {
    @apply fixed inset-0;
    @apply bg-black bg-opacity-50;
    @apply z-modal;
  } 
</style>