<script lang="ts">
  // Components
  import { Grid } from '$lib/components/organisms/Layout';
  import { NavbarLogo } from '$lib/components/atoms/NavbarLogo';
</script>

<footer class="footer">
  <Grid>
    <section class="company-info">
      <NavbarLogo light />
    </section>
    <section class="footer-navigation-1st-col">
      <ul class="footer-main-nav">
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/about">Chi siamo</a>
        </li>
      </ul>
    </section>
    <section class="footer-navigation-2st-col">
      <ul class="footer-main-nav">
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/faq">FAQ</a>
        </li>
      </ul>
    </section>
    <section class="footer-navigation-3st-col">
      <ul class="footer-main-nav">
        <li class="footer-nav-item">
          <a class="footer-nav-item-link" href="/docenti">Docenti</a>
        </li>
      </ul>
    </section>
  </Grid>
</footer>

<style type="text/scss" lang="scss" scoped>
  .footer {
    @apply py-8 md:py-10;
    @apply bg-gray-900;
    @apply text-white;
  }

  .company-info {
    @apply col-span-full md:col-span-4;
  }

  .footer-navigation-1st-col {
    @apply col-span-full md:col-span-3 lg:col-span-2;
  }

  .footer-navigation-2st-col {
    @apply col-span-full md:col-span-2;
  }

  .footer-navigation-3st-col {
    @apply col-span-full md:col-span-3 lg:col-span-4;
  }

  .footer-navigation-1st-col,
  .footer-navigation-2st-col,
  .footer-navigation-3st-col {
    @apply pt-grid-default border-t border-white border-opacity-30;
    @apply md:pt-0 md:border-t-0;
  }

  .footer-main-nav .footer-nav-item + .footer-nav-item {
    @apply mt-3;
  }

  .footer-nav-item-link {
    @apply relative;
    @apply text-md text-white font-serif font-medium;

    &:after {
      content: "";
      @apply lg:absolute lg:left-0 lg:bottom-0.5;
      @apply lg:h-0.5 lg:bg-white lg:w-full lg:bg-opacity-30;
      @apply lg:transform lg:scale-x-0;
      @apply lg:ease-in-out lg:duration-200 lg:origin-right;
      transition-property: transform;
    }

    &:hover:after {
      @apply lg:transform lg:scale-x-100;
      @apply lg:origin-left;
    }
  }
</style>
