<script lang="ts">
  // Components
  import { Navbar } from '$lib/components/organisms/Layout';
  import { NavbarLogo } from '$lib/components/atoms/NavbarLogo';
  import { NavbarModalBack } from '$lib/components/organisms/Modal';
  
  export let fixed = true
</script>

<!-- Set fluid to 'true' to extend it full width -->
<Navbar fixed="{fixed}" fluid="{false}">
  <NavbarLogo />

  <svelte:fragment slot="main">
    <NavbarModalBack />
  </svelte:fragment>
</Navbar>
